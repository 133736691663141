/* CSS Document */

/* Polices */
@font-face {
    font-family: 'Muli-Bold';
    src: url('/fonts/Muli-Bold.eot');
    src: url('/fonts/Muli-Bold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Muli-Bold.woff2') format('woff2'),
    url('/fonts/Muli-Bold.ttf') format('truetype'),
    url('/fonts/Muli-Bold.svg#OpenSans-Light') format('svg');
}

@font-face {
    font-family: 'Muli-SemiBold';
    src: url('/fonts/Muli-SemiBold.eot');
    src: url('/fonts/Muli-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Muli-SemiBold.woff2') format('woff2'),
    url('/fonts/Muli-SemiBold.ttf') format('truetype'),
    url('/fonts/Muli-SemiBold.svg#OpenSans-Light') format('svg');
}

@font-face {
    font-family: 'Muli-Regular';
    src: url('/fonts/Muli-Regular.eot');
    src: url('/fonts/Muli-Regular.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Muli-Regular.woff2') format('woff2'),
    url('/fonts/Muli-Regular.ttf') format('truetype'),
    url('/fonts/Muli-Regular.svg#OpenSans-Light') format('svg');
}

@font-face {
    font-family: 'Museo';
    src: url('/fonts/Museo-700.eot');
    src: url('/fonts/Museo-700.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Museo-700.woff2') format('woff2'),
    url('/fonts/Museo-700.ttf') format('truetype'),
    url('/fonts/Museo-700.svg#OpenSans-Light') format('svg');
}

/* Global */

/* Je surcharge les classes de .min.css pour mettre notre image */
.chosen-container-single .chosen-single abbr {
    background: url('/img/bt/chosen-sprite.png') -42px 1px no-repeat;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Muli', sans-serif;
    font-size: 12px;
    color: #282828;
    min-width: 320px;
    overflow-x: hidden;
}

.container {
    width: 80%;
    margin: 0 auto;
}

.strike {
    text-decoration: line-through;
}

h1, div.titre_h1 {
    font-weight: normal;
    font-size: 26px;
    line-height: 1.3;
    color: #282828;
    text-transform: uppercase;
    font-family: 'Museo', sans-serif;
}

h2, div.titre_h2 {
    font-weight: normal;
    font-size: 26px;
    line-height: 1.3;
    color: #282828;
    text-transform: uppercase;
    font-family: 'Museo', sans-serif;
}

h3, div.titre_h3 {
    font-weight: normal;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 10px;
    color: #282828;
    text-transform: uppercase;
    font-family: 'Museo', sans-serif;
}

h4, div.titre_h4 {
    font-size: 14px;
    color: #336699;
    font-weight: normal;
}

p {
    margin: 0;
    padding: 10px 0;
    text-align: left;
    line-height: 1.5;
}

a {
    color: #cf3124;
    text-decoration: none;
}

a:hover {
    color: #282828;
    text-decoration: none;
}

hr {
    position: relative;
    display: block;
    height: 1px;
    background-color: #a42d22;
    overflow: hidden;
    border: none;
    margin-bottom: 25px;
    margin-top: 25px;
}

img {
    border: none;
    max-width: 100%;
}

.rounded {
    max-height: 150px;
    border-radius: 100%;
}

.bg_blanc {
    background-color: #fff;
}
.bg-gris {
    background-color: #eee;
}

.flex {
    display: flex;
}
.flex-col { flex-direction: column }
.flex-row { flex-direction: row }

.row {
    margin-left: 0;
    margin-right: 0;
}

button {
    cursor: pointer;
}

/* UI messages */
.msg_erreur {
    background-color: #fff6f6;
    color: #912d2b;
    font-size: 14px;
    font-family: 'Muli-Bold', sans-serif;
    padding: 15px 20px;
    margin: 10px 0 30px;
    border: 1px solid #ec8e86;
    border-radius: 3px;
    font-style: normal;
}

.msg_erreur span {
    display: block;
    color: #9f3a38;
    font-size: 13px;
    font-family: 'Muli', sans-serif;
    font-weight: normal;
}

.msg_erreur a {
    color: #9f3a38;
    font-size: 13px;
    font-family: 'Muli', sans-serif;
    font-weight: normal;
}

.msg_info {
    background-color: #eef9f9;
    color: #0e566c;
    font-size: 14px;
    font-family: 'Muli-Bold', sans-serif;
    padding: 15px 20px;
    margin: 10px 0 30px;
    border: 1px solid #a9d5de;
    border-radius: 3px;
}

.msg_info span {
    display: block;
    color: #0e566c;
    font-size: 13px;
    font-family: 'Muli', sans-serif;
    font-weight: normal;
}

.msg_info a {
    color: #0e566c;
    font-size: 13px;
    font-family: 'Muli', sans-serif;
    font-weight: normal;
}

.msg_warning {
    background-color: #fffaf3;
    color: #794b02;
    font-size: 14px;
    font-family: 'Muli-Bold', sans-serif;
    padding: 15px 20px;
    margin: 10px 0 30px;
    border: 1px solid #c9ba9b;
    border-radius: 3px;
}

.msg_warning span {
    display: block;
    color: #573a08;
    font-size: 13px;
    font-family: 'Muli', sans-serif;
    font-weight: normal;
}

.msg_warning a {
    color: #573a08;
    font-size: 13px;
    font-family: 'Muli', sans-serif;
    font-weight: normal;
}

.error_msg {
    text-align: center;
    width: 100%;
    font-size: 16px;
    font-family: 'Museo 700', sans-serif;
    margin-bottom: 20px;
}

.toast {
    opacity: 1 !important;
}

.soon {
    color: currentColor;
    cursor: not-allowed;
    opacity: 0.5;
    text-decoration: none;
}

/*////////// Couleurs //////////*/
.rouge {
    color: #a42d22;
}

.noir {
    color: #282828;
}

.jaune {
    color: #ffa805;
}

/*////////// Boutons //////////*/
.bouton,
a.bouton {
    display: inline-block;
    background-color: #cf3124;
    color: #fff;
    border: 2px solid transparent;
    border-radius: 0;
    width: auto;
    transition: all .2s;
    font-family: 'Muli-Bold', sans-serif;
    font-size: 16px;
    margin: 10px auto 20px;
    text-transform: uppercase;
    padding: 10px 15px;
}

.bouton:hover,
a.bouton:hover {
    color: #cf3124;
    background-color: #fff;
    opacity: 1;
    border-color: #cf3124;
}

.bt-gris,
a.bt-gris {
    background-color: #999;
    color: #fff;
}

.bt-gris:hover,
a.bt-gris:hover {
    background-color: #fff;
    color: #999;
    border-color: #999;
}

.bt-min,
a.bt-min {
    padding: 10px 15px;
    font-size: 12px;
}

.bt-100 {
    border: 1px solid #cf3124;
    text-transform: uppercase;
    text-align: center;
    font-family: "Muli-Bold", sans-serif;
    font-size: 18px;
    padding: 0;
}

.bt-100 a {
    color: #cf3124;
    width: 100%;
    display: block;
    transition: all .2s;
    padding: 10px 0;
}

.bt-100 a:hover {
    color: #fff;
    background-color: #cf3124;
}

.bt-retour,
a.bt-retour {
    color: #888;
    background: transparent;
    border: none;
    text-transform: unset;
    font-size: 13px;
    padding: 0;
}

.bt-retour:hover,
a.bt-retour:hover {
    color: #282828;
}

.bt-retour i,
a.bt-retour i {
    margin-right: 10px;
}

.bt-secondary,
a.bt-secondary {
    background-color: transparent;
    color: #CF3124;
    border-color: #CF3124;
    font-size: 14px;
}

.bt-secondary:hover,
a.bt-secondary:hover {
    background-color: #CF3124;
    color: #fff;
}

.bt-secondary:disabled {
    cursor: not-allowed;
    opacity: 0.8;
}
.bt-secondary:hover:disabled {
    background-color: transparent;
    color: #CF3124;
    border-color: #CF3124;
    font-size: 14px;
}

/*////////// Formulaires //////////*/
.form_pns .form_group {
    margin: 20px 0 40px;
}

.form_pns label,
.form_pns .label_like {
    font-size: 18px;
    font-family: 'Muli-Bold', sans-serif;
    margin: 10px 0 10px 0;
    padding: 15px 0 0;
    display: block;
}

.form_pns .radio_group input[type=radio] {
    margin-right: 10px;
}

.form_pns .radio_group input[type=radio] + label {
    display: inline-block;
    margin-right: 30px;
    padding-top: 0;
    margin-top: 5px;
}

.form_pns .select_group select {
    width: 49%;
}

.form_pns input[type=text],
.form_pns input[type=date],
.form_pns input[type=email],
.form_pns input[type=tel],
.form_pns input[type=password],
.form_pns input[type=file],
.form_pns input[type=url],
.form_pns input[type=number],
.form_pns select,
.chosen-container,
.chosen-single,
.chosen-choices,
.form_pns textarea {
    width: 100%;
    font-size: 18px;
    border: 1px solid transparent;
    background-color: #f4f4f4;
    color: #282828;
    padding: 10px 15px;
}

.form_pns input[type=text]:focus,
.form_pns input[type=email]:focus,
.form_pns input[type=tel]:focus,
.form_pns input[type=password]:focus,
.form_pns input[type=file]:focus,
.form_pns input[type=url]:focus,
.form_pns select:focus,
.form_pns .chosen-container:focus,
.form_pns textarea:focus {
    outline: none;
    border: 1px solid #cf3124;
}

.form_pns textarea {
    min-height: 100px;
}

.chosen-container .chosen-drop {
    left: 0;
}

.chosen-container-active.chosen-with-drop .chosen-single {
    border-color: transparent;
    background-image: none;
    box-shadow: none;
}

.chosen-container .chosen-results li.highlighted {
    background-color: #cf3124;
    background-image: none;
}

.chosen-single,
.chosen-choices,
.chosen-container-single .chosen-single {
    background-image: none !important;
    box-shadow: none !important;
    padding: 0 !important;
    border: none !important;
    font-size: 16px !important;
}

.chosen-container-multi .chosen-single li.search-choice,
.chosen-container-multi .chosen-choices li.search-choice {
    background-color: #cf3124;
    font-weight: bold;
    color: #fff;
    padding: 8px 25px 8px 15px;
    border: none;
    background-image: none;
    box-shadow: none;
    line-height: 15px;
}

.chosen-container span,
.chosen-container-single .chosen-single span {
    margin: 0;
}

.form_pns .label_file {
    display: inline-block;
    padding: 5px 15px;
    color: #fff;
    font-size: 16px;
    background-color: #CF3124;
    border-radius: 4px;
    border: 2px solid #cf3124;
    cursor: pointer;
    transition: all 0.2s ease;
}

.form_pns .label_file + input[type=file] {
    display: none;
}

.form_pns .label_file:hover {
    color: #CF3124;
    background-color: transparent;
    border-color: #CF3124;
}

.form_pns input[type=submit],
.form_pns button[type=submit],
.form_pns input[type=button] {
    display: block;
    background-color: #cf3124;
    border: 2px solid transparent;
    border-radius: 0;
    width: auto;
    color: #fff;
    transition: all .2s;
    font-family: 'Muli-Bold', sans-serif;
    font-size: 16px;
    margin: 10px auto 20px;
    text-transform: uppercase;
    padding: 15px;
    cursor: pointer;
}

.form_pns input[type=submit]:hover,
.form_pns button[type=submit]:hover,
.form_pns input[type=button]:hover {
    color: #cf3124;
    background-color: #fff;
    opacity: 1;
    border: 2px solid #cf3124;
    font-weight: bold;
}

.form_pns span :not(span) > .cke {
    margin-top: 15px;
}

.form_pns .rgpd {
    display: flex;
    align-items: baseline;
    margin-top: 15px;
}

.form_pns .rgpd label {
    font-family: 'Muli', sans-serif;
    padding-left: 10px;
    font-size: 12px !important;
    margin-left: 5px;
    line-height: 1.5;
}


/*////////// HEADER //////////*/
header {
    position: relative;
    box-shadow: 0 2px 6px rgba(55, 55, 55, 0.1);
    z-index: 50;
}

/*Navigation*/
#navigation {
    display: flex;
    align-items: center;
    position: relative;
    height: 70px;
}

#logo {
    text-align: center;
    height: 100%;
    z-index: 50;
}

#logo img {
    width: 115px;
    height: 115px;
    position: absolute;
    top: 10%;
}

#logo::before {
    content: '';
    background: url("/img/icone/points-cercle.svg") no-repeat;
    width: 200px;
    height: 200px;
    position: absolute;
    left: 38%;
    top: -50%;
}

nav ul {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
}

nav ul li {
    width: calc(100% / 5);
    padding: 20px 0;
}

nav ul li:not(#compte) {
    text-align: center;
}

nav ul li a {
    color: #252324;
    text-decoration: none;
    font-size: 16px;
    font-family: 'Muli-Bold', sans-serif;
    position: relative;
}

nav ul li:not(:last-of-type) a::after {
    content: '';
    position: absolute;
    height: 2px;
    background-color: #cf3124;
    width: 0;
    left: 0;
    top: 100%;
    transition: all .2s;
}

nav ul li:not(:last-of-type) a:hover::after {
    width: 100%;
}

#compte {
    background-color: #f4f4f4;
}

#compte img {
    width: 25px;
    margin: 0 15px;
}

#compte a {
    color: #cf3124;
}

#compte .gris {
    display: block;
    font-size: 12px;
    text-transform: lowercase;
    font-family: 'Muli', sans-serif;
    margin-left: 55px;
    line-height: 13px;
}

#compte.connected {
    padding: 14px 0;
}

#compte_mobile,
#burger_menu {
    display: none;
}

/*////////// MAIN //////////*/

/* Aside */
aside {
    position: fixed;
    right: 2rem;
    top: 35%;
    z-index: 50;
}

aside > ul {
    padding: 0;
}

aside > ul > li {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #b41818;
    width: 3rem;
    height: 3rem;
    border-radius: 100%;
    margin: 1rem 0;
    cursor: pointer;
    transition: all .2s ease-in-out;
    /*border: solid 1px white;*/
    box-shadow: -1px 0px 0px 2px #9e040a;

}

aside > ul > li:hover {
    background: #e30613;
}

aside > ul > li img {
    width: 2rem;
    height: 2rem;
}

aside > ul > li > ul {
    display: none;
    list-style: none;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
    height: auto;
    text-align: center;
    background: #fff;
    padding: .5rem 1rem;
    z-index: 50;
    border: 2px solid #e30613;
    border-radius: 5px;
}

aside > ul > li > ul > li a {
    font-size: normal;
}

/* Slider offres */
#slider-offre {
    padding: 0;
}

#slider-offre .slick-arrow {
    appearance: none;
    position: absolute;
    left: 2rem;
    height: 1.25rem;
    width: 2rem;
    border: none;
    color: transparent;
    background: url("/img/icone/chevron-left-red.svg") no-repeat center, transparent;
    background-size: contain;
    z-index: 1000;
    border:none;
}

#slider-offre .slick-arrow:hover, .slick-arrow:focus {
    filter: brightness(150%);
}

#slider-offre .slick-next {
    transform: rotate(-90deg);
    transform-origin: center;
    bottom: 40%;
}

#slider-offre .slick-prev {
    transform: rotate(90deg);
    transform-origin: center;
    top: 40%;
}

#slider-offre .slick-dots {
    height: 0;
    width: 0;
    margin: 0;
    margin-block-start: 0px;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0;
}

#slider-offre .slick-dots li {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 2rem;
    height: 2rem;
    width: 2rem;
    list-style: none;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

#slider-offre .dot {
    color: white;
    font-size: 10px;
    font-weight: bold;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 100%;
    background: #e21e26;
    border: solid 1px #b32025;
    display: none;
    box-shadow: -1px 0px 0px 2px #b32025;
}

#slider-offre .slick-active > .dot {
    display: flex;
    justify-content: center;
    align-items: center;
}

/*#slider-offre .dot {*/
/*    position: relative;*/
/*    display: block;*/
/*    width: 10px;*/
/*    height: 10px;*/
/*    border-radius: 100%;*/
/*    background: #e30613;*/
/*    cursor: pointer;*/
/*}*/

/*#slider-offre .slick-active > .dot::after {*/
/*    content: "";*/
/*    position: absolute;*/
/*    left: 50%;*/
/*    top: 50%;*/
/*    width: 16px;*/
/*    height: 16px;*/
/*    transform: translate(-50%, -50%);*/
/*    border: 1px solid #e30613;*/
/*    border-radius: 100%;*/
/*    background: transparent;*/
/*}*/


#slider-offre .liste_offres--item {
    display: flex !important;
    align-items: center;
}

#slider-offre .offre-left {
    height: 30rem;
    clip-path: polygon(95% 0, 100% 15%, 100% 50%, 100% 85%, 95% 95%, 95% 100%, 0 100%, 0 0)
}

#slider-offre .offre-left a {
    display: block;
    height: 100%;
}

#slider-offre .offre-left img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: left;
}

#slider-offre .offre-right {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem 4rem 2rem 10rem;
    align-self: stretch;
}

#slider-offre .offre-right::before {
    content: "";
    position: absolute;
    left: -6rem;
    top: 0;
    width: 155px;
    height: 100%;
    background: url("/img/icone/cercle-slider.svg") no-repeat;
    background-size: contain;
}

#slider-offre .offre-right h2 {
    font-size: 32px;
    margin-bottom: 2rem;
    color: #b41818;
}

#slider-offre .offre-right p {
    padding-left: 5%
}

#slider-offre.liste_offres .liste_offres--item .item--bottom p::before {
    left: 0;
}

/*Bannière*/

#banniere {
    padding-top: 10rem;
    margin-top: 0;
}

.banniere {
    position: relative;
    /*background: url("/medias/img/banniere.jpg") no-repeat 50%;*/
    background-size: cover;
    height: 700px;
    overflow: visible;
}

.banniere .absolute,
#banniere .absolute {
    position: absolute;
    right: -4rem;
    top: -4rem;
    padding: 3rem 5rem 3rem 4rem;
    background: #b41818;
    color: #fff;
    border-radius: 100%;
    font-size: 16px;
    width: 22rem;
    height: 18rem;
    z-index: 10;
}
#banniere .absolute-left {
    left: -4rem;
    padding: 3rem 4rem 3rem 5rem;
    height: 14rem;
}
.banniere .absolute p {
    font-size: 20px;
    padding: 0;
    font-weight: bold;
}

.banniere .absolute ul {
    list-style: inside;
    padding: 0;
    margin-bottom: 1rem;
}
.banniere .absolute span {
    display: block;
    margin: 1rem 0;
}
.banniere .absolute a {
    display: block;
    text-align: center;
    font-weight: bold;
    border: 2px solid #fff;
    color: #fff;
    padding: .5rem 1rem;
    transition: all .2s;
}

.banniere .absolute a:hover {
    background: #fff;
    color: #b41818;
}

.banniere > .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1%;
}

.banniere .title h1 {
    font-size: 50px;
    color: #cf3124;
    text-transform: none;
    font-family: 'Museo', sans-serif;
    line-height: 1;
    text-align: center;
}

.banniere .title h1 span {
    display: block;
    color: #282828;
    padding: 5px 0;
    font-size: 40px;
}

.banniere > .blocs {
    display: flex;
    height: auto;
    padding-top: 7%;
}

.banniere__bloc > .bloc__top {
    padding: 75px 0 15px;
    position: relative;
    height: 45%;
}

.banniere__bloc > .bloc__top::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: url("/img/icone/points-cercle-demi.svg") no-repeat;
    opacity: .3;
    width: 300px;
    height: 100%;
}

.banniere__bloc--left > .bloc__top::before {
    opacity: .3;
}

.banniere__bloc--right > .bloc__top::before {
    opacity: 1;
}

.banniere__bloc > .bloc__top > .img-rond {
    border: 4px solid #fff;
    border-radius: 100%;
    width: 170px;
    height: 170px;
    overflow: hidden;
    position: absolute;
    top: -75%;
    left: 50%;
    transform: translateX(-50%);
}

.banniere__bloc > .bloc__top > .img-rond > img {
    width: 170px;
    height: 170px;
    object-fit: cover;
    position: relative;
}

.banniere__bloc > .bloc__top > p {
    position: relative;
    width: 80%;
    margin: 0 auto;
    text-transform: uppercase;
    font-size: 22px;
    font-family: 'Muli-Bold', sans-serif;
    text-align: center;
    padding: 0;
}

.banniere__bloc > .bloc__top > p > a {
    display: block;
    padding: 10px 0;
    transition: all 0.3s ease;
}

.banniere__bloc > .bloc__top > p > a:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.banniere__bloc > .bloc__top > p > a::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 35px;
    width: 35px;
    transition: all 0.3s ease;
}

.banniere__bloc > .bloc__top > p > a:hover:before {
    transform: translateY(-60%);
}

.banniere__bloc--left > .bloc__top {
    background-color: #a42d22;
}

.banniere__bloc--left > .bloc__top > p > a {
    border: 1px solid #fff;
}

.banniere__bloc--left > .bloc__top > p, .banniere__bloc--left > .bloc__top > p > a {
    color: #fff;
}

.banniere__bloc--left > .bloc__top > p > a::before {
    left: 10%;
    background: url('/img/icone/boussole.svg') no-repeat;
}

.banniere__bloc--right > .bloc__top {
    background-color: #fff;
}

.banniere__bloc--right > .bloc__top > p > a {
    border: 1px solid #282828;
}

.banniere__bloc--right > .bloc__top > p > a {
    color: #282828;
}

.banniere__bloc--right > .bloc__top > p > a::before {
    left: 8%;
    background: url('/img/icone/jumelles.svg') no-repeat;
}

.banniere__bloc > .bloc__bottom {
    height: 55%;
    padding: 5px 50px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.banniere__bloc--left > .bloc__bottom {
    background-color: rgba(164, 45, 34, .5);
    color: #fff;
}

.banniere__bloc--right > .bloc__bottom {
    background-color: rgba(255, 255, 255, .65);
    color: #282828;
}

.banniere__bloc > .bloc__bottom > p {
    text-align: center;
    font-size: 16px;
    font-family: 'Muli', sans-serif;
    padding-bottom: 0;
}

.banniere__bloc > .bloc__bottom > p:first-of-type {
    text-transform: uppercase;
    font-size: 20px;
    font-family: 'Muli-Bold', sans-serif;
}

.banniere__bloc--right > .bloc__bottom > p:nth-of-type(2) {
    text-transform: uppercase;
    font-family: 'Muli-Bold', sans-serif;
    padding: 5px 0;
}

/*Sections*/
section {
    padding: 35px 0;
    /*overflow: hidden;*/
}

.section-title {
    position: relative;
    width: 80%;
    padding-bottom: 20px;
    margin: 0 auto 50px;
    line-height: 1.3;
    text-align: center;
}

.section-title:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 300px;
    margin: auto;
    height: 1px;
    background-color: #999899;
}

.section-title-fiche{
    margin-top: 2rem;
}

.section-title > img {
    width: 70px;
    height: 70px;
    margin-bottom: 20px;
}

.section-breadcrumb {
    margin-bottom: 1rem;
    font-size: 18px;
}

/*A la une*/
.top-job {
    background-color: #f4f4f4;
    padding: 0 0 70px 0;
}

.top-job > p {
    background-color: #fff;
    padding: 7px 0 7px 28%;
    font-family: "Museo", sans-serif;
    font-size: 25px;
    border-top: 5px solid #f4f4f4;
}

.top-job > .job-desc {
    display: flex;
    align-items: flex-end;
    padding-top: 65px;
    position: relative;
}

.top-job > .job-desc > .job-desc--img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 265px;
    height: 265px;
    overflow: hidden;
    position: absolute;
    left: 2%;
    top: -20%;
}

.top-job > .job-desc > .job-desc--img > img {
    width: 96%;
    height: 96%;
    border-radius: 100%;
    object-fit: cover;
}

.top-job > .job-desc > .job-desc--img::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: url("/img/icone/cercle.svg") no-repeat;
    width: 100%;
    height: 100%;
}

.top-job > .job-desc > .c_30 > p {
    padding: 0;
    line-height: 1.5;
}

.top-job > .job-desc > .job-desc--left > p {
    position: relative;
    line-height: 2.5;
    color: #6f6f6f;
    font-family: 'Muli-Bold', sans-serif;
    font-size: 18px;
}

.top-job > .job-desc > .job-desc--left > p:first-of-type {
    color: #797979;
    font-family: 'Muli', sans-serif;
}

.top-job > .job-desc > .job-desc--left > p::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    left: -7%;
    top: 50%;
    transform: translateY(-50%);
}

.top-job > .job-desc > .job-desc--left > p:nth-of-type(2)::before {
    background: url("/img/icone/map.svg") no-repeat;
}

.top-job > .job-desc > .job-desc--left > p:nth-of-type(3)::before {
    background: url("/img/icone/mallette.svg") no-repeat;
}

.top-job > .job-desc > .job-desc--right > p {
    color: #222;
    font-size: 16px;
}

/*Offres*/
.form_offres {

}

.form_offres > form {
    display: flex;
    flex-direction: column;
    margin-bottom: 4%;
}

.form_offres > form > .form_top {
    width: 100%;
    background: #a42d22;
    padding: 15px 0;
}

.form_offres .btn-recherche {
    display: inline-block;
    background-color: #cf3124;
    color: #fff;
    border: 2px solid transparent;
    border-radius: 0;
    width: auto;
    transition: all .2s;
    font-family: 'Muli-Bold', sans-serif;
    font-size: 18px;
    margin: 20px auto 10px;
    text-transform: uppercase;
    padding: 15px 60px;
}

.form_offres .template-recherche {
    display: flex;
}

.search_offer_simple {
    width: 100%;
    background: #a42d22;
    padding: 15px 0;
}

.search_offer_simple input[type="submit"] {
    border: 1px solid #fff;
    background: #a42d22;
    font-family: 'Muli-Bold', sans-serif;
    font-size: 18px;
    color: #fff;
    transition: all .2s;
}

.search_offer_simple input[type="submit"]:hover {
    background: #fff;
    color: #a42d22;
    opacity: 1;
}


.form_offres > form > .form_top > .content {
    display: flex;
    justify-content: space-between;
}

.form_offres > form > .form_top > .content > *, .search_offer_simple input {
    width: calc(100% / 4 - 20px);
    background-color: #fff;
    border: 0;
    border-radius: 0;
    padding: 15px 10px;
    text-align: center;
    color: #282828;
    text-transform: uppercase;
    font-family: 'Muli-bold', sans-serif;
}

.form_offres > form > .form_top > .content > input[type=submit] {
    border: 1px solid #fff;
    background: #a42d22;
    font-family: 'Muli-Bold', sans-serif;
    font-size: 18px;
    color: #fff;
    transition: all .2s;
}

.form_offres > form > .form_top > .content > input[type=submit]:hover {
    background: #fff;
    color: #a42d22;
    opacity: 1;
}

.form_offres > form > .form_bottom {
    background: #f4f4f4;
    width: 100%;
    padding: 25px 0;
    position: relative;
}

.form_offres > form > .form_bottom::after {
    content: '';
    background: url('/img/icone/points-ligne-gris.svg') no-repeat;
    position: absolute;
    left: 0;
    top: 88%;
    width: 100%;
    height: 100px;
    background-size: cover;
    z-index: -1;
}

.form_offres > form > .form_bottom > .content > .filtre-agence,
.form_offres > form > .form_bottom > .content > .filtre-tag {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    padding-bottom: 15px;
}

.form_offres > form > .form_bottom > .content > .filtre-agence > p,
.form_offres > form > .form_bottom > .content > .filtre-tag > p {
    font-size: 16px;
    width: 10%;
    margin-right: 20px;
}

.form_offres > form > .form_bottom > .content > .filtre-agence > input,
.form_offres > form > .form_bottom > .content > .filtre-tag > input {
    display: none;
}

.form_offres > form > .form_bottom > .content > .filtre-agence > label,
.form_offres > form > .form_bottom > .content > .filtre-tag > label {
    border: 1px solid #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Muli-Bold', sans-serif;
    font-size: 16px;
    margin-right: 20px;
    transition: all .2s;
}

.form_offres > form > .form_bottom > .content > .filtre-agence > label {
    color: #a42d22;
    text-transform: uppercase;
    margin-top: 10px;
}

.form_offres > form > .form_bottom > .content > .filtre-agence > label p,
.form_offres > form > .form_bottom > .content > .filtre-type > label p {
    padding: 10px;
}

.form_offres > form > .form_bottom > .content > .filtre-agence > label:hover {
    color: rgba(255, 255, 255, .95);
    background: rgba(164, 45, 34, .8);
}

.form_offres > form > .form_bottom > .content > .filtre-agence > input:checked + label {
    color: #fff;
    background: #a42d22;
}

.form_offres > form > .form_bottom > .content > .filtre-tag > label {
    color: #222;
    width: calc(100% / 9 - 25px);
    margin-top: 10px;
}

.form_offres > form > .form_bottom > .content > .filtre-tag > label:hover {
    color: rgba(255, 255, 255, .95);
    background: rgba(34, 34, 34, 0.8);
}

.form_offres > form > .form_bottom > .content > .filtre-tag > input:checked + label {
    color: #fff;
    background: #222;
}

.form_offres > form > .form_bottom > .content > .filtre-type {
    display: flex;
    justify-content: center;
    align-items: center;
}

.form_offres > form > .form_bottom > .content > .filtre-type > label {
    text-transform: uppercase;
    font-size: 18px;
    margin-left: 10px;
}

.form_offres > form > .form_bottom > .content > .filtre-type > label:not(:last-of-type) {
    margin-right: 60px;
}

.liste_offres {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.liste_offres > .liste_offres--item {
    width: calc(100% / 3 - 50px);
    margin-bottom: 50px;
    position: relative;
    display: block;
    transition: all 0.2s ease;
}

.liste_offres > .liste_offres--item:hover {
    transform: translateY(-10px);
}

.liste_offres > .liste_offres--item:hover .item--bottom {
    box-shadow: 0 15px 35px 15px rgba(0, 0, 0, 0.085);
}

.liste_offres > .liste_offres--item > .item--top {
    background-color: #cf3124;
    padding-left: 35%;
}

.liste_offres > .liste_offres--item > .item--top > p {
    color: #fff;
    font-size: 18px;
    font-family: 'Museo', sans-serif;
}

.liste_offres > .liste_offres--item > .item--img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 115px;
    height: 115px;
    overflow: hidden;
    position: absolute;
    left: 2%;
    top: 0;
}

.liste_offres > .liste_offres--item > .item--img > img {
    width: 95%;
    height: 95%;
    object-fit: cover;
    border-radius: 100%;
    background: #fff;
}

.liste_offres > .liste_offres--item > .item--img::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: url("/img/icone/cercle.svg") no-repeat;
    width: 100%;
    height: 100%;
}

.liste_offres > .liste_offres--item > .item--bottom {
    background-color: #fafafa;
    padding: 20px 10px 20px 35%;
    box-shadow: 0 10px 30px 10px rgba(0, 0, 0, .1);
    transition: all 0.2s ease;
}

.liste_offres .liste_offres--item .item--bottom > p {
    position: relative;
    padding-top: 15px;
    color: #6f6f6f;
    font-family: 'Muli-Bold', sans-serif;
    line-height: 1;
    font-size: 16px;
}

.liste_offres .liste_offres--item .item--bottom p:first-of-type {
    color: #797979;
    font-family: 'Muli', sans-serif;
}

.liste_offres .liste_offres--item .item--bottom p::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    left: -10%;
    top: 50%;
    transform: translateY(-50%);
}

.liste_offres .liste_offres--item .item--bottom p:nth-of-type(1)::before {
    background: url("/img/icone/house.svg") no-repeat;
}

.liste_offres .liste_offres--item .item--bottom p:nth-of-type(2)::before {
    background: url("/img/icone/map.svg") no-repeat;
}

.liste_offres .liste_offres--item .item--bottom p:nth-of-type(3)::before {
    background: url("/img/icone/mallette.svg") no-repeat;
}

#offres {
    padding-bottom: 70px;
}

.pagination {
    text-align: center;
}

.pagination span {
    display: inline-block;
    margin: 0 1px;
    background: #cf3124;
    color: #fff;
    font-family: 'muli-bold', sans-serif;
    font-size: 16px;
    border: 2px solid #cf3124;
    cursor: pointer;
    transition: all 0.3s ease;
}

.pagination span.next {
    margin-left: 20px;
}

.pagination span.previous {
    margin-right: 20px;
}

.pagination span.first,
.pagination span.last,
.pagination span.next,
.pagination span.previous {
    background: transparent;
}

.pagination span.first a,
.pagination span.last a,
.pagination span.next a,
.pagination span.previous a {
    color: #cf3124;
    padding: 8px 10px;
}

.pagination a {
    color: #fff;
    display: block;
    padding: 13px 13px;
}

.pagination span:hover,
.pagination span:hover a,
.pagination span.current {
    background: transparent;
    color: #cf3124;
}

.pagination span.current {
    padding: 13px 13px;
}


/*Statistiques*/
#statistiques {
    /*background: url("/medias/img/banniere-2.jpg") no-repeat center;*/
    position: relative;
    padding-top: 75px;
}

#statistiques > .section-title {
    margin-bottom: 25px;
}

#statistiques > .section-title:after {
    background-color: #fff;
}

#statistiques h2, #statistiques h2 .rouge {
    color: #fff !important;
}

#statistiques::before, #statistiques::after {
    content: '';
    background: url("/img/icone/points-ligne-blanc.svg") no-repeat;
    background-size: cover;
    position: absolute;
    width: 100%;
    height: 45px;
}

#statistiques::before {
    top: 1%;
}

#statistiques::after {
    bottom: 1%;
    transform: rotate(180deg);
}

.stat_presentation > .stat_presentation--haut > p {
    color: #fff;
    text-align: center;
    font-size: 22px;
    font-family: "Muli", sans-serif;
}

.stat_presentation > .stat_presentation--bas {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 20px 0 50px 0;
}

.stat_presentation > .stat_presentation--bas > .presentation_bas--left {
    position: relative;
}

.stat_presentation > .stat_presentation--bas img {
    width: 165px;
    height: 165px;
    margin-bottom: 25px;
}

.stat_presentation > .stat_presentation--bas span {
    color: #fff;
    position: absolute;
    top: 12%;
    left: 30%;
    font-size: 40px;
    font-family: 'Museo', sans-serif;
}

.stat_presentation > .stat_presentation--bas p {
    color: #fff;
    text-transform: uppercase;
    font-family: 'Museo', sans-serif;
    font-size: 30px;
    text-align: center;
}

/* Infos partenaires */
#infos-partenaires > .container > p {
    color: #222;
    font-size: 16px;
    text-align: center;
    margin: 0 auto;
}

#infos-partenaires .liste-partenaires {
    justify-content: center;
}

#infos-partenaires .liste-partenaires a {
    display: block;
    margin: 0 2rem;
}

/*Partenaires*/
#partenaires > .container > p {
    width: 45%;
    color: #222;
    font-size: 16px;
    text-align: center;
    margin: 0 auto;
}

#partenaires > .section-title {
    margin: 0 auto 30px;
}

.liste-partenaires {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
}

.liste-partenaires > img {
    width: 150px;
    height: 100%;
}

/* Actualités */
#news {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
#news > .bouton {
    margin-top: 3rem;
}

/*Agences*/
#agences {
    padding: 35px 0 0 0;
    background-color: #f4f4f4;
    position: relative;
}

#agences::before {
    content: '';
    position: absolute;
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    width: 105%;
    height: 225%;
    border-radius: 100%;
}

#agences .section-title  p {
    text-align: center;
    font-size: 1.3rem;

}

.bloc_agence {
    background-color: #f4f4f4;
    min-height: 370px;
    position: relative;
}

.liste_agence {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.liste_agence--logo {
    width: 160px;
    height: 160px;
    position: absolute;
    top: -40%;
    left: 5%;
    z-index: 2;
}

.liste_agence--logo::before {
    content: '';
    background: url(/img/icone/points-cercle.svg) no-repeat;
    width: 310px;
    height: 310px;
    position: absolute;
    left: -47%;
    top: -52%;
    z-index: 1;
}

.liste_agence--logo img {
    position: relative;
    z-index: 2;
}

.liste_agence > .liste_agence--item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all .2s;
    letter-spacing: 3px;
    width: 20%;
    height: 150px;
}

.liste_agence > .liste_agence--item .bg_gris {
    position: absolute;
    width: 125px;
    height: 125px;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 100%;
    background-color: #f4f4f4;
    transition: all .2s;
}

.liste_agence > .liste_agence--item-1 {
    margin-left: 12%;
}

.liste_agence > .liste_agence--item-10 {
    margin-right: 8%;
}

.liste_agence > .liste_agence--item > a {
    z-index: 2;
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
}

.liste_agence > .liste_agence--item > a > .agence-nom {
    position: relative;
    width: 100%;
    color: #cf3124;
    font-family: "Museo", sans-serif;
    text-transform: uppercase;
    font-size: 20px;
    text-align: center;
    z-index: 1;
}

.liste_agence > .liste_agence--item > a > .agence-nom p {
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    margin: auto;
    top: 55px;
    transition: all 0.1s ease;
}

.liste_agence > .liste_agence--item-10 > a > .agence-nom p {
    padding: 0;
}

.grow {
    transition: all .3s;
}

.grow .bg_gris {
    transform: scale(1.3);
}

/*////////// FOOTER //////////*/
/*Footer haut*/
.footer-top {
    background-color: #a42d22;
    display: flex;
    justify-content: space-around;
    padding: 40px 0;
    position: relative;
    z-index: 1;
}

.footer-top > .form-footer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 70%;
}

.footer-top > .form-footer > p {
    color: #fff;
    font-family: "Museo", sans-serif;
    font-size: 16px;
}

.footer-top > .form-footer > form {
    background-color: #cf3124;
    padding: 10px;
    position: relative;
    width: 60%;
    display: flex;
    justify-content: space-between;
}

.footer-top > .form-footer > form::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 3%;
    background: url("/img/icone/mail.svg") no-repeat;
    width: 25px;
    height: 20px;
    z-index: 2;
}

.footer-top > .form-footer > form > input[type=email] {
    background-color: #fff;
    padding: 10px 10px 10px 8%;
    width: 65%;
    color: #222;
}

.footer-top > .form-footer > form > input[type=submit] {
    background-color: #cf3124;
    padding: 5px;
    border: 1px solid #fff;
    border-radius: 0;
    width: 32%;
    transition: all .2s;
    font-family: 'Muli-Bold', sans-serif;
    font-size: 16px;
}

.footer-top > .form-footer > form > input[type=submit]:hover {
    color: #cf3124;
    background-color: #fff;
    opacity: 1;
}

.footer-top > hr {
    background-color: #cf3124;
    height: 3px;
    width: 3%;
    transform: rotate(90deg);
    position: absolute;
    right: 17%;
    top: 30%;
}

.footer-top > .rs-footer {
    display: flex;
    justify-content: space-around;
    width: 15%;
}

.footer-top > .rs-footer > a {
    background-color: #cf3124;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .2s;
}

.footer-top > .rs-footer > a:hover {
    background-color: #6f1e18;
}

.footer-top > .rs-footer > a > img {
    width: 30px;
    height: 30px;
}

/*Footer bas*/
.footer-bottom {
    background-color: #3f4040;
    padding: 10px 0 15px;
}

.footer-bottom > p {
    text-align: center;
    color: #fff;
    padding: 0;
    font-size: 14px;
    line-height: 2;
}

.footer-bottom > p:first-of-type {
    text-transform: uppercase;
}

.footer-bottom > p > a {
    color: #fff;
    position: relative;
}

.footer-bottom > p > a:not(:last-of-type) {
    margin-right: 20px;
}

.footer-bottom > p > a:not(:first-of-type)::before {
    content: '-';
    position: absolute;
    left: -15px;
}

/*////////// ACCOMPAGNEMENT //////////*/

/* Bannière */
/*#banniere-accompagnement::before {*/
/*content: '';*/
/*position: absolute;*/
/*background: url("/img/icone/points-cercle.svg") no-repeat;*/
/*height: 400px;*/
/*width: 400px;*/
/*left: 22%;*/
/*top: 35%;*/
/*}*/
.banniere_texte {
    font-family: 'Museo', sans-serif;
    position: relative;
    height: 100%;
}

.banniere_texte p {
    padding: 0;
    position: absolute;
    line-height: 1;
}

.banniere_texte p:first-of-type {
    font-size: 60px;
    border: 1px solid #282828;
    padding: 15px 20px 20px 150px;
    left: 43%;
    top: 30%;
}

.banniere_texte p:last-of-type {
    font-size: 50px;
    border: 1px solid #cf3124;
    padding: 25px 75px 25px 20px;
    left: 50%;
    top: 42%;
}

/* Charte */
.charte {
    position: relative;
}

.charte__arrow .arrow {
    background-color: #252324;
    height: 55px;
    width: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 5;
    transition: all .2s;
}

.charte__arrow .arrow:hover {
    background-color: #282828;
}

.charte__arrow .prevarrow {
    left: 9%;
}

.charte__arrow .nextarrow {
    right: 9%;
}

.charte__arrow .arrow img {
    height: 25px;
}

.charte__liste {
    width: 75%;
    margin: 0 auto;
}

.charte__liste--item {
    position: relative;
    padding: 0 25px 15px;
}

.charte__item--img {
    transform: translate(0%, 25%);
    background-color: #fff;
    border-radius: 100%;
    height: 130px;
    width: 130px;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.charte__item--img img {
    height: 65px;
    width: 65px;
    z-index: 2;
}

.charte__item--img::before {
    content: '';
    position: absolute;
    height: 145px;
    width: 145px;
    border-radius: 100%;
    background-color: rgba(255, 255, 255, 0.3);
}

.charte__item--img::after {
    content: '';
    position: absolute;
    background: url("/img/icone/cercle.svg") no-repeat;
    width: 115px;
    height: 115px;
    top: 7%;
    left: 0;
    right: 0;
    margin: auto;
}

.charte__item--titre {
    background-color: #cf3124;
    color: #fff;
    padding: 45px 15px 5px;
    font-family: 'Museo', sans-serif;
    font-size: 20px;
    text-align: center;
}

.charte__item--texte {
    text-align: center;
    background-color: #fafafa;
    padding: 50px 25px 25px;
    font-size: 16px;
    min-height: 25vh;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

/* Accompagnement */
#accompagnement .bloc__top {
    height: 300px;
}

#accompagnement .banniere__bloc > .bloc__top {
    padding: 25px 0 15px;
}

#accompagnement .banniere__bloc > .bloc__top > .img-rond {
    top: 70%;
}

#accompagnement .banniere__bloc > .bloc__top::before {
    background: url('/img/icone/points-cercle.svg') no-repeat;
    top: 50%;
}

#accompagnement .banniere__bloc > .bloc__top > p > a {
    margin-bottom: 15px;
    text-transform: uppercase;
    font-size: 18px;
}

#accompagnement .banniere__bloc--left > .bloc__top > p > a::before {
    left: 4%;
}

#accompagnement .banniere__bloc--right > .bloc__top > p > a::before {
    left: 2%;
}

#accompagnement .banniere__bloc > .bloc__top > p {
    text-transform: initial;
    font-size: 16px;
}

#accompagnement .banniere__bloc > .bloc__top > p:nth-of-type(2) {
    font-size: 24px;
}

#accompagnement .bloc__bottom {
    height: 225px;
}

#accompagnement .bloc__bottom p {
    font-size: 16px;
    text-transform: initial;
    padding-top: 100px;
}

/* Inscription */
.inscription__form {
    position: relative;
    margin-bottom: 150px;
}

.inscription__form::after {
    content: '';
    position: absolute;
    background: url(/img/icone/points-ligne-gris.svg) no-repeat;
    width: 100vw;
    height: 100%;
    left: -13%;
    top: 100%;
}

.inscription__form form {
    background-color: #f4f4f4;
    display: flex;
    flex-direction: column;
    padding: 10% 50px 25px 45%;
    min-height: 25vh;
    position: relative;
}

.inscription__form form .form__img {
    position: absolute;
    width: 225px;
    height: 225px;
    left: 2%;
    top: 0;
    overflow: hidden;
    border-radius: 100%;
    z-index: 1;
}

.inscription__form form .form__img::after {
    content: '';
    position: absolute;
    background: url("/img/icone/cercle.svg") no-repeat;
    background-size: cover;
    width: 225px;
    height: 225px;
    z-index: 1;
    top: 0;
    left: 0;
}

.inscription__form form .form__img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.inscription__form form p {
    z-index: 0;
    font-family: 'Museo', sans-serif;
    font-size: 20px;
    background-color: #fff;
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    padding-left: 35%;
    border-top: 5px solid #f4f4f4;
}

.inscription__form form input {
    background-color: #fff;
    border: none;
    padding: 10px 15px;
    margin-bottom: 10px;
    color: #282828;
}

.inscription__form form input[type=text]:focus,
.inscription__form form input[type=email]:focus,
.inscription__form form input[type=password]:focus {
    border: none;
    outline: none;
}

.inscription__form form label {
    transform: translateY(-25px);
    color: #5a5a5a;
    text-transform: uppercase;
    padding-left: 15px;
}

.inscription__form form a {
    color: #797979;
    padding-top: 15px;
    transition: all .2s;
}

.inscription__form form a:hover {
    color: #cf3124;
}

.inscription__form form .input-submit {
    position: absolute;
    bottom: -40%;
    width: 75%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #cf3124;
    z-index: 1;
    display: flex;
    justify-content: center;
}

.inscription__form form .input-submit input {
    background-color: transparent;
    color: #fff;
    margin: 10px;
    border: 1px solid #fff;
    text-transform: uppercase;
    padding: 10px 50px;
    text-align: center;
    border-radius: 0;
    font-size: 18px;
    transition: all .2s;
}

.inscription__form form .input-submit input:hover {
    background-color: #fff;
    color: #cf3124;
    font-weight: bold;
    opacity: 1;
}

/* Infos */
#info p {
    text-align: center;
    font-size: 20px;
}

#info button {
    /*width: 100%;*/
    width: auto;
    background-color: transparent;
    border: 1px solid #cf3124;
    margin: 50px auto;
    text-transform: uppercase;
    transition: all .2s;
    display: flex;
    justify-content: center;
}

#info button:hover {
    background-color: rgba(255, 255, 255, 0.7);
}

#info button a {
    color: #cf3124;
    font-weight: bold;
    font-size: 24px;
    display: block;
    width: auto;
    height: 100%;
    padding: 15px;
}

/*////////// CONTACT //////////*/
.contact__content {
    display: flex;
    align-items: center;
}

#contact .section-title  p {
    text-align: center;
    font-size: 1.5rem;

}

#map {
    width: 100%;
    height: 500px;
}

/*////////// AGENCE //////////*/
#agence {
    padding: 35px 0 0;
}

.agence__content {
    background-color: #f4f4f4;
    position: relative;
}

.agence__content::after {
    content: '';
    position: absolute;
    background: url('/img/icone/points-ligne-gris.svg');
    width: 100%;
    height: 20%;
    left: 0;
    top: 99%;
    z-index: -1;
}

.agence--photo {
    position: relative;
}

.agence--photo img {
    max-width: 100%;
}

.agence--photo > div {
    margin: -50px 0 50px 10%;
    border-left: 5px solid #cf3124;
    background-color: rgba(255, 255, 255, .9);
    position: relative;
    width: 85%;
    padding: 15px 35px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1)
}

.agence--photo > div p,
.agence--photo > div li {
    font-size: 18px;
}

.agence--photo > div p {
    padding: 5px 0;
}

.agence--coord {
    padding: 50px 0;
    position: relative;
}

.agence--coord img {
    position: absolute;
    width: 150px;
    height: 150px;
    top: 5%;
    left: -180px;
}

.agence--coord p {
    position: relative;
    font-size: 18px;
    padding: 0 0 0 40px;
    line-height: 2;
}

.agence--coord p.agence__adresse {
    line-height: 1;
}

.agence--coord p.agence__adresse2 {
    font-weight: bold;
}

.agence--coord p.agence__adresse::before {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    background: url('/img/icone/location.svg') no-repeat;
    background-size: contain;
    left: 0;
    top: 20%;
}

.agence--coord p.agence__tel::before {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    background: url('/img/icone/phone.svg') no-repeat;
    background-size: contain;
    left: 0;
    top: 20%;
}

.agence--coord p.agence__mail::before {
    content: '';
    position: absolute;
    width: 23px;
    height: 23px;
    background: url('/img/icone/mail.svg') no-repeat;
    background-size: contain;
    left: 0;
    top: 20%;
}

.agence--coord p.agence__fb::before {
    content: '';
    position: absolute;
    width: 23px;
    height: 23px;
    background: url('/img/icone/fb.svg') no-repeat;
    background-size: contain;
    left: 0;
    top: 20%;
}

.agence--coord iframe {
    width: 100%;
    height: 500px;
}

.agence--coord p:last-of-type {
    margin-bottom: 25px;
}

/*////////// OFFRE //////////*/
.offre__liste--item {
    background-color: #f4f4f4;
    margin-bottom: 25px;
    padding: 15px 10px;
}

/*.liste_offres--item > .item--bottom > button {
    background: transparent;
    border: none;
    margin: 25px auto;
}*/
.liste_offres--item .item--bottom a {
    display: inline-block;
    background-color: #cf3124;
    color: #fff;
    border: 2px solid transparent;
    border-radius: 0;
    width: auto;
    transition: all .2s;
    font-family: 'Muli-Bold', sans-serif;
    font-size: 16px;
    margin: 20px auto 10px;
    text-transform: uppercase;
    padding: 5px 15px;
}

.liste_offres--item .item--bottom a:hover {
    color: #cf3124;
    background-color: #fff;
    opacity: 1;
    border: 2px solid #cf3124;
    font-weight: bold;
}

/*////////// OFFRE DETAIL //////////*/
.offre__content {
    background-color: #f4f4f4;
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    position: relative;
}

.offre__content::after {
    content: '';
    position: absolute;
    background: url(/img/icone/points-ligne-gris.svg);
    width: 100%;
    height: 20%;
    left: 0;
    top: 99%;
}

.offre__content > .offre--img {
    width: 350px;
    height: 350px;
    overflow: hidden;
    position: relative;
}

.offre__content > .offre--img::after {
    content: '';
    position: absolute;
    background: url("/img/icone/cercle.svg") no-repeat;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.offre__content > .offre--img > img {
    width: 97%;
    height: 97%;
    border-radius: 100%;
    object-fit: cover;
}

.offre__content p {
    padding: 0;
    line-height: 1.5;
}

.offre__content > .offre__content--left > p {
    position: relative;
    line-height: 2.5;
    color: #6f6f6f;
    font-family: 'Muli-Bold', sans-serif;
    font-size: 18px;
}

.offre__content > .offre__content--left > p:first-of-type {
    color: #797979;
    font-family: 'Muli', sans-serif;
}

.offre__content > .offre__content--left > p::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    left: -7%;
    top: 50%;
    transform: translateY(-50%);
}

.offre__content > .offre__content--left > p:nth-of-type(2)::before {
    background: url("/img/icone/map.svg") no-repeat;
}

.offre__content > .offre__content--left > p:nth-of-type(3)::before {
    background: url("/img/icone/mallette.svg") no-repeat;
}

.offre__content > .offre__content--left > p:nth-of-type(4)::before {
    background: url("/img/icone/calendar.svg") no-repeat;
    width: 25px;
    height: 25px;
    left: -7.5%;
}

.offre__content--right {
    padding: 40px 0 60px;
}

.offre__content > .offre__content--right p,
.offre__content > .offre__content--right li
{
    color: #222;
    font-size: 16px;
}

/*.offre__content > .offre__content--right > button {
    background: transparent;
    border: none;
    margin: 50px auto 0;
}*/
.offre__content > .offre__content--right > a {
    display: inline-block;
    background-color: #cf3124;
    color: #fff;
    border: 2px solid transparent;
    border-radius: 0;
    width: auto;
    transition: all .2s;
    font-family: 'Muli-Bold', sans-serif;
    font-size: 16px;
    margin: 25px 20px 10px 0;
    text-transform: uppercase;
    padding: 15px;
}

.offre__content > .offre__content--right > a:hover {
    color: #cf3124;
    background-color: #fff;
    opacity: 1;
    border: 2px solid #cf3124;
    font-weight: bold;
}

#popup_postuler {
    margin: 40px 10%;
}

#popup_postuler h2 {
    margin: 10px 0;
    color: #a42d22;
    font-size: 22px;
}

.expired {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99999;
    width: 100%;
    height: 100vh;
    background: url("/img/bg/expired.png") no-repeat center center, url("/img/bg/overlay.png") repeat;
}

.expired a {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: 50px;
    transform: translate(-50%, -50%);
    font-size: 1.2rem;
}

/* Connexion */
.popup {
    margin: 40px 10%;
}
.popup.flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.popup h2 {
    margin: 10px 0;
    color: #a42d22;
    font-size: 22px;
}

.popup a {
    color: #282828;
}
.popup a.blanc {
    color: white;
}

a#lien_inscription {
    display: block;
    font-size: 20px;
    font-family: 'Museo', sans-serif;
    color: #a42d22;
    margin: 30px 0 0;
}

#login_service > .label {
    font-size: 18px;
}
#login_service {
    align-items: center;
    margin-top: 8px;
}
#login_service a {
    display: inline-block;
    background: white;
    color: #444;
    width: 190px;
    border-radius: 5px;
    border: thin solid #888;
    box-shadow: 1px 1px 1px grey;
    white-space: nowrap;
    margin: 4px auto 0 auto;
}
#login_service .bt-icon {
    display: inline-block;
    vertical-align: middle;
    width: 42px;
    height: 42px;
}
#login_service .bt-google .bt-icon {
    background: url('/img/icone/google.svg') transparent 5px 50% no-repeat;
}
#login_service .bt-apple .bt-icon {
    background: url('/img/icone/apple.svg') transparent 5px 50% no-repeat;
}
#login_service .bt-text {
    display: inline-block;
    vertical-align: middle;
    padding-left: 42px;
    padding-right: 42px;
    font-size: 16px;
    font-weight: bold;
    /* Use the Roboto font that is loaded in the <head> */
    font-family: 'Roboto', sans-serif;
}

/* Trouver un candidat */
#banniere-candidat {
    height: 550px;
}

#banniere-candidat .banniere__bloc {
    width: 50%;
    margin: 80px auto;
}

#banniere-candidat .banniere__bloc p:last-child {
    position: relative;
    width: 80%;
    margin: 0 auto;
    text-transform: uppercase;
    font-size: 22px;
    font-family: 'Muli-Bold', sans-serif;
    text-align: center;
    padding: 0;
}

#banniere-candidat .banniere__bloc p:last-child a {
    display: block;
    color: #282828;
    background-color: #fff;
    margin: 20px auto;
    padding: 10px 0;
    border: 1px solid #282828;
    transition: all 0.3s ease;
}

#banniere-candidat .banniere__bloc p:last-child a:hover {
    background-color: rgba(255, 255, 255, 0.5);
}

#banniere-candidat .banniere__bloc p:last-child a:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 8%;
    transform: translateY(-50%);
    height: 35px;
    width: 35px;
    transition: all 0.3s ease;
    background: url('/img/icone/jumelles.svg') no-repeat;
}

#banniere-candidat .banniere__bloc p:last-child a:hover:before {
    transform: translateY(-60%);
}

#candidats {
    margin-bottom: 60px;
}

#candidats > .container > p {
    width: 45%;
    color: #222;
    font-size: 16px;
    text-align: center;
    margin: 0 auto;
}

.liste_cv {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 50px;
}

.liste_cv > .liste_cv--item {
    width: calc(100% / 3 - 50px);
    margin-bottom: 50px;
    position: relative;
    display: block;
    transition: all 0.2s ease;
}

.liste_cv > .liste_cv--item:hover {
    transform: translateY(-10px);
}

.liste_cv > .liste_cv--item:hover .item--bottom {
    box-shadow: 0 15px 35px 15px rgba(0, 0, 0, 0.085);
}

.liste_cv > .liste_cv--item > .item--top {
    background-color: #cf3124;
    padding-left: 35%;
}

.liste_cv > .liste_cv--item > .item--top > p {
    color: #fff;
    font-size: 18px;
    font-family: 'Museo', sans-serif;
}

.liste_cv > .liste_cv--item > .item--img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 115px;
    height: 115px;
    overflow: hidden;
    position: absolute;
    left: 2%;
    top: 0;
}

.liste_cv > .liste_cv--item > .item--img > img {
    width: 95%;
    height: 95%;
    border-radius: 100%;
}

.liste_cv > .liste_cv--item > .item--img::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: url("/img/icone/cercle.svg") no-repeat;
    width: 100%;
    height: 100%;
}

.liste_cv > .liste_cv--item > .item--bottom {
    background-color: #fafafa;
    padding: 40px 0 30px 35%;
    box-shadow: 0 10px 30px 10px rgba(0, 0, 0, .1);
    transition: all 0.2s ease;
}

.liste_cv > .liste_cv--item > .item--bottom > p {
    position: relative;
    padding-top: 15px;
    color: #6f6f6f;
    font-family: 'Muli-Bold', sans-serif;
    line-height: 1;
    font-size: 16px;
}

.liste_cv > .liste_cv--item > .item--bottom > p:first-of-type::before {
    background: url("/img/icone/map.svg") no-repeat;
}

.liste_cv > .liste_cv--item > .item--bottom > p::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    left: -10%;
    top: 50%;
    transform: translateY(-50%);
}

.liste_cv > .liste_cv--item > .item--bottom > p:nth-of-type(2)::before {
    background: url("/img/icone/mallette.svg") no-repeat;
}

.liste_cv > .liste_cv--item > .item--bottom > p:nth-of-type(3) {
    color: #797979;
    font-family: 'Muli', sans-serif;
}


/* Pages de contenu */
#contenu_page {
    position: relative;
}

#contenu_page:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 90px;
    background-color: #f4f4f4;
    z-index: -1;
}

#contenu_page:after {
    content: '';
    background: url('/img/icone/points-ligne-gris.svg') no-repeat;
    position: absolute;
    left: 0;
    top: 85px;
    width: 100%;
    height: 150px;
    background-size: cover;
    z-index: -1;
}

main {
    min-height: 65vh;
    overflow: hidden;
}

#contenu_page section {
    min-height: 65vh;
    padding: 85px 0 35px;
    overflow: hidden;
    margin: 0 10% 50px;
    font-size: 14px;
    line-height: 1.8;
}

#contenu_page h1 {
    font-size: 36px;
    font-family: 'Museo', sans-serif;
    text-transform: uppercase;
    margin: 10px 0 20px;
}

#points_bas {
    position: relative;
}

#points_bas:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 40px;
    background-color: #f4f4f4;
    z-index: -1;
}

#points_bas:after {
    content: '';
    background: url('/img/icone/points-ligne-gris.svg') no-repeat;
    transform: rotate(180deg);
    position: absolute;
    left: 0;
    bottom: 35px;
    width: 100%;
    height: 150px;
    background-size: cover;
    z-index: -1;
}


/* Pages de compte */
#contenu_page #page_compte {
    padding-top: 25px;
}

#page_compte h1,
#page_newsletter h1 {
    color: #282828;
}

#page_compte .section-title,
#page_newsletter .section-title {
    margin-bottom: 20px;
}

#page_compte .groupe-bt,
#page_newsletter .groupe-bt {
    justify-content: space-between;
    align-items: center;
}

#page_compte .groupe-bt > *,
#page_newsletter .groupe-bt > * {
    margin: 20px 0;
}

#page_compte .groupe-bt.center {
    justify-content: center;
}

#page_compte .groupe-bt.center > * {
    margin: 20px 40px;
}

.groupe-bt a.bouton {
    margin-top: 10px !important;
    line-height: 1.15em;
}

.groupe-bt a.bouton.bt-retour {
    margin-top: 20px !important;
}

#page_compte .navigation,
#page_newsletter .navigation {
    margin-top: 30px;
}

.greeting {
    margin-right: 11%;
    font-size: 18px;
    font-family: 'Museo 700', sans-serif;
}

#tableau_bord, #tableau_bord_fiche{
    display: flex;
    justify-content: space-between;
    padding-top: 20px !important;
    min-height: 450px !important;
}

#tableau_bord > *, #tableau_bord_fiche > * {
    margin: 10px;
    display: flex;
    flex-grow: 1;
}

.tb_entreprise > *,
.tb_recruteur > * {
    width: 33%;
}

.tb_candidat > * {
    width: 25%;
}

.tb_admin > * {
    width: 25%;
}

#tableau_bord a, #tableau_bord_fiche a {
    background-color: #f4f4f4;
    border: 5px solid #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    margin: 10px;
    text-align: center;
    flex-grow: 1;
    flex-basis: 50%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s ease;
}

#tableau_bord > div, #tableau_bord_fiche > div {
    flex-direction: column;
    align-items: center;
}

#tableau_bord > div > a, #tableau_bord_fiche > div > a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

#tableau_bord a p, #tableau_bord_fiche a p {
    width: 85%;
    text-align: center;
    margin: auto;
    color: #888;
    font-size: 12px;
    padding: 0;
}

#tableau_bord h2, #tableau_bord_fiche h2 {
    margin: 10px 0;
}

/*#tableau_bord .tb_compte{
    flex-grow: 2;
    margin: 0 !important;
}
#tableau_bord .tb_logout{
    flex-grow: 1;
    margin: 20px 0 0!important;
}*/
#tableau_bord a:hover, #tableau_bord_fiche a:hover {
    background-color: #f9e1de;
}



.form_compte .flex {
    align-items: center;
    margin-top: 20px;
    justify-content: flex-start;
}

#tableau_offres .ligne_head {
    font-family: 'Muli-Bold', sans-serif;
    padding: 15px 0;
    border-bottom: 2px solid #cf3124;
}

#tableau_offres .ligne {
    padding: 15px 0;
    border-bottom: 1px solid #eee;
}

.hidden {
    position: relative;
    margin: 0 0 10px;
}

.hidden label {
    margin-top: 0;
}

.suppr_ligne {
    position: absolute;
    top: 15px;
    right: 0;
    z-index: 1;
}

.input-hidden {
    visibility: hidden !important;
    display: none !important;
    height: 0;
    width: 0;
}

/* Newsletters */
.nav_nl {
    background: #f4f4f4;
    margin-right: 2%;
    width: 13%;
    margin-top: 15px;
}

.nav_nl a {
    display: block;
    font-size: 13px;
    padding: 10px 15px;
    transition: all 0.3s ease;
}

.nav_nl a.active {
    font-family: 'Muli-Bold', sans-serif;
    background-color: #ececec;
    color: #282828;
}

.nav_nl a:hover {
    background-color: #ececec;
}

/* Msg */
.msg_link {
    font-family: 'Muli', sans-serif;
    font-size: 13px !important;
}

.msg_link a {
    text-decoration: underline;
    font-size: 14px;
    font-family: 'Muli-Bold', sans-serif;
}

/* Pages d'erreur */
#error {
    min-height: 70vh;
    font-size: 16px;
}

#error p {
    text-align: center;
}

/* Relevés d'heures */
.table-responsive .ligne_head {
    font-family: 'Muli-Bold', sans-serif;
    padding: 15px 0;
    border-bottom: 2px solid #cf3124;
}

.table-responsive .ligne {
    padding: 15px 0;
    border-bottom: 1px solid #eee;
}

.releve_semaine {
    font-family: 'Muli-Bold', sans-serif;
    color: #CF3124;
    text-transform: uppercase;
    font-size: 12px;
}

.table_entry {
    margin: 20px 0 0 0;
    background-color: #fff;
    border: 1px solid #e6e6e6;
    padding: 20px 80px;
    border-radius: 4px;
    width: 700px;
    max-width: 100%;
    margin: auto;
}

.timingfield {
    margin: 25px auto 0;
}

.timingfield .timingfield_hours .input-group .input-group-addon,
.timingfield .timingfield_minutes .input-group .input-group-addon,
.timingfield .timingfield_seconds .input-group .input-group-addon {
    position: absolute;
    top: -56px;
    width: 100%;
    font-size: 11px;
    text-transform: uppercase;
    font-family: 'Muli-Bold';
}

.table-responsive .table-secondary {
    background-color: transparent;
}

/* CV */
.show_resume .bords_arrondis {
    padding: 30px;
    border-radius: 10px;
    border-color: #e6e6e6;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.show_resume aside {
    background-color: #f4f4f4;
    border-radius: 10px;
    padding: 20px 30px;
}

.show_resume aside h3 {
    font-size: 18px;
}

.show_resume p,
.show_resume a {
    font-size: 16px;
}

.show_resume a.bt-retour {
    font-size: 13px;
}

.show_resume p {
    padding-top: 0;
}

.show_resume .cadre_pad {
    padding: 15px 0 10px 20px;
    margin: 15px 0 25px;
    background-color: #f4f4f4;
    border-radius: 4px;
}

.show_resume .cadre_pad span {
    font-size: 11px;
    color: #888;
    text-transform: uppercase;
    display: block;
}

.timesheet-company-edit textarea, input[type=text] {
    width: 95%;
}

.timesheet-multiline {
    line-height: 1em !important;
}

#popup-info {
    display: none;
    place-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, .6);
    z-index: 50;
}

#popup-info .popup-content {
    position: relative;
    width: 45%;
    background: #fafafa;
    padding: 15px 35px 25px;
    text-align: center;
    border-radius: 5px;
}

#popup-info .popup-close {
    display: block;
    margin: 1rem auto 0;
    cursor: pointer;
}

/*////////// Actualités / News //////////*/
.bt-social-share__container {
    display: flex;
    align-items: center;
}
.bt-social-share__icon {
    width: 18px;
    height: 18px;
    margin-right: 8px;
}

.news {
    max-width: 1000px;
    height: auto;
    font-size: 16px;
}
.news__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.news__head .bouton.bt-retour {
    margin: 0;
    margin-right: auto;
}
.news__informations {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}
.news__image {
    height: 280px;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.news__image img {
    display: inline-block;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.news-frame__list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 2rem;
}
.news-frame {
    font-size: 14px;
    display: flex;
    width: 45%;
    justify-content: space-between;
}

.news-frame__image {
    width: 180px;
    overflow: hidden;
}
.news-frame__image img {
    max-height: 160px;
    width: 100%;
    object-fit: cover;
    transition: 0.2s
}
.news-frame__image:hover img{
    transform: scale(1.05);
}

.news-frame__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 1rem;
}
.news-frame__sub {
    display: flex;
    margin-top: 0.5rem;
    align-items: center;
}
.news-frame__publication {
    font-weight: bold;
}
.news-frame__read .bouton {
    margin: 0;
}
.news-frame__read {
    margin-left: auto;
}

.form_news__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.form_news__header .bouton {
    margin: 0;
    margin-right: auto;
}
.form_news__header label {
    margin: 0;
    padding: 0;
    margin-left: 8px;
}
.form_news__header .bt-checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
}
.form_news .news-img {
    display: flex;
    justify-content: center;
    padding: 1rem;
}
.form_news .news-img img {
    height: 200px;
}

.z-60{
    z-index: 60;
}

