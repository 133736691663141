@charset "utf-8";
/* CSS responsive */

/* Desktop low res */
@media (max-width: 1600px) {

	/* Header */
	nav ul li a{
		font-size: 14px;
	}
	#compte{
		height: 70px;
		padding: 25px 0;
	}
	#compte a{
		display: block;
	}
	#compte img {
		width: 20px;
		margin: 0 10px;
	}
	#compte .gris{
		margin-left: 40px;
	}

	#slider-offre .offre-right {
		padding: 2rem 4rem 2rem 5rem;
	}
	.banniere__bloc > .bloc__top > p{
		width: 90%;
		font-size: 20px;
	}
	.banniere__bloc > .bloc__top > p::before{
		left: 5%;
	}
	.banniere__bloc--right > .bloc__top > p > a::before{
		left: 4%;
	}
	.top-job > .job-desc > .job-desc--img{
		left: -2%;
	}
	.liste_offres > .liste_offres--item {
		width: calc(100% / 3 - 30px);
	}
	.liste_offres > .liste_offres--item > .item--img{
		left: -20px;
		top: -15px;
	}
	.liste_offres > .liste_offres--item > .item--top{
		padding-right: 10px;
	}
	.liste_offres > .liste_offres--item > .item--bottom{
		padding-left: 20%;
	}
	.liste_offres > .liste_offres--item > .item--bottom > p::before{
		left: -15%;
	}
	.bloc_agence .c_10{
		display: none;
	}
	.bloc_agence .liste_agence{
		width: 90%;
		margin: auto;
		float: none;
	}
	.liste_agence > .liste_agence--item > .agence-show{
		width: 110%;
		font-size: 22px;
	}
	#banniere-candidat .banniere__bloc {
		width: 60%;
	}
	.liste_cv > .liste_cv--item > .item--img{
		left: -10%;
	}
	.liste_cv > .liste_cv--item > .item--bottom > p::before{
		left: -17%;
	}
	.charte__liste {
		width: 100%;
	}
	.charte__arrow .prevarrow {
		left: -5%;
	}
	.charte__arrow .nextarrow {
		right: -5%;
	}
	#accompagnement .bloc__bottom {
		height: auto;
	}
	.inscription__form form .form__img{
		width: 180px;
		height: 180px;
		left: -5%;
	}
	.inscription__form form .form__img::after{
		width: 180px;
		height: 180px;
	}
	#contenu_page section{
		margin: 0 5% 50px;
	}
	.offre__content > .offre--img {
		width: 315px;
		height: 315px;
	}
	.offre__content > .offre__content--left > p::before,
	.offre__content > .offre__content--left > p:nth-of-type(4)::before{
		left: -50px;
	}

	#accompagnement .c_10{
		width: 5%;
	}
	#accompagnement > .blocs{
		width: 90%;
	}
	.banniere__bloc {
		width: 47.5%;
	}
	.banniere__bloc > .bloc__top > .img-rond{
		width: 140px;
		height: 140px;
		top: -53%;
	}
	.banniere__bloc > .bloc__top > .img-rond > img {
		width: 140px;
		height: 140px;
	}
	.banniere__bloc > .bloc__top > p {
		font-size: 18px;
	}
	.banniere__bloc > .bloc__top > p > a::before{
		width: 30px;
		height: 30px;
	}
	.banniere__bloc > .bloc__bottom {
		padding: 5px 30px 20px;
	}

}

/* Tablettes */
@media (max-width: 1281px) {
	/* Header */
	#logo {
		//width: 10%;
	}
	#logo img{
		margin: 0;
		top: 5px;
		left: 10px;
		width: 90px;
		height: 90px;
		z-index: 2;
	}
	#logo::before{
		display: none;
	}
	nav {
		width: 90% !important;
	}
	nav ul li {
		margin-right: 10px;
	}
	nav ul li a{
		line-height: 18px;
	}

	aside {
		position: fixed;
		right: 1rem;
		top: 70%;
		z-index: 50;
	}

	aside > ul{
		display: flex;
		justify-content: space-between;
		margin: 20px 0px 0px 0px;
	}

	aside > ul > li {
		margin-right: 0.75rem;
		margin-top: 0;
		margin-bottom: 0;
	}

	aside > ul > li > ul {
		display: none;
		list-style: none;
		position: fixed;
		top:0;
		left: 50%;
		transform: translateX(-50%);
		width: 100vw;
		height: 100vh;
		text-align: center;
		background: rgba(255,255,255,0.8);
		backdrop-filter: blur(15px);
		padding: .5rem 1rem;
		z-index: 50;
		border: 2px solid #e30613;
		border-radius: 5px;

	}

	aside > ul > li > ul > li  {
		padding: 1rem;
		border-bottom: solid 1px #e30613;
	}

	aside > ul > li > ul > li a {
		font-size: x-large;
	}

	#compte{
		padding: 10px 0;
		margin: 0 0 0 5px;
		width: 100px;
		flex-grow: 1;
	}
	#compte.connected {
		padding: 10px 0;
	}
	#compte a{
		font-size: 10px;
		text-align: center;
	}
	#compte img {
		margin: 0 auto 5px;
		display: block;
	}
	#compte .gris {
		display: none;
	}

	/* Accueil */
	#slider-offre .offre-right {
		padding: 2rem 2rem 2rem 5rem;
	}
	#slider-offre .offre-right p {
		padding-left: 10%;
	}
	.banniere .title h1{
		font-size: 40px;
	}
	.banniere .title h1 span{
		font-size: 30px;
	}
	.container {
		width: 90%;
	}
	.section-title{
		width: 100%;
	}
	.section-title > img {
		width: 60px;
		height: 60px;
	}
	#banniere .c_10{
		width: 5%;
	}
	#banniere .blocs{
		width: 90%;
	}
	.top-job > p{
		font-size: 22px;
	}
	.top-job > .job-desc{
		padding-top: 20px;
	}
	.top-job > .job-desc > .job-desc--img{
		width: 230px;
		height: 230px;
		left: -4%;
	}
	.top-job > .job-desc > .job-desc--left > p::before{
		left: -15%;
	}
	.form_offres > form > .form_top .c_10,
	.form_offres > form > .form_bottom .c_10{
		width: 5%;
	}
	.form_offres > form > .form_top > .content,
	.form_offres > form > .form_bottom > .content{
		width: 90%;
	}
	.form_offres > form > .form_top > .content > *{
		font-size: 12px;
	}
	.form_offres > form > .form_bottom > .content > .filtre-agence,
	.form_offres > form > .form_bottom > .content > .filtre-tag{
		flex-wrap: wrap;
	}
	.form_offres > form > .form_bottom > .content > .filtre-agence > p,
	.form_offres > form > .form_bottom > .content > .filtre-tag > p{
		width: 120px;
	}
	.form_offres > form > .form_bottom > .content > .filtre-agence > label,
	.form_offres > form > .form_bottom > .content > .filtre-tag > label{
		width: auto;
		padding: 0 10px;
	}
	.liste_offres > .liste_offres--item {
		width: calc(100% / 2 - 30px);
	}
	.liste_offres > .liste_offres--item > .item--top{
		padding-left: 30%;
	}
	#statistiques .c_30{
		width: 15%;
	}
	.stat_presentation{
		width: 70%;
	}
	.liste-partenaires > img{
		max-width: 150px;
		max-height: 100px;
		width: auto;
		height: auto;
	}
	.liste_agence > .liste_agence--logo {
		width: 135px;
		height: 135px;
		left: 2%;
	}
	.liste_agence > .liste_agence--logo .bg_gris {
		width: 300px;
		height: 300px;
		left: -60%;
		top: -65%;
	}
	.liste_agence > .liste_agence--item {
		margin-left: 0;
		letter-spacing: 2px;
	}
	.footer-top{
		align-items: flex-end;
		padding: 30px 0 40px;
	}
	.footer-top > .rs-footer{
		width: 20%;
		align-items: center;
		margin-bottom: 5px;
	}
	.footer-top > .form-footer{
		flex-direction: column;
		align-items: flex-start;
	}
	.footer-top > .form-footer > form{
		width: 100%;
	}
	.footer-top > hr{
		right: 20%;
		top: 45%;
	}

	/* Offre */
	.offre__content > .offre--img {
		width: 230px;
		height: 230px;
		position: absolute;
		left: -3%;
		top: -5%;
	}
	.offre__content{
		padding: 20px 10px 20px 27%;
		align-items: flex-start;
	}
	.offre__content--left{
		width: 35%;
	}
	.offre__content--right {
		padding: 0;
		width: 60%;
	}

	/* Trouver un candidat */
	#banniere-candidat .banniere__bloc {
		width: 70%;
	}
	#banniere-candidat .banniere__bloc p:last-child{
		font-size: 18px;
	}
	.liste_cv > .liste_cv--item {
		width: calc(100% / 2 - 50px);
	}

	/* Notre accompagnement */
	#banniere-accompagnement{
		height: 500px;
	}
	.banniere_texte p:first-of-type {
		font-size: 50px;
		top: 25%;
	}
	.banniere_texte p:last-of-type {
		font-size: 40px;
	}
	.charte__liste {
		width: 90%;
	}
	.charte__liste--item {
		padding: 0 15px 15px;
	}
	.charte__arrow .prevarrow {
		left: -3%;
	}
	.charte__arrow .nextarrow {
		right: -3%;
	}
	#accompagnement > .blocs{
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.banniere__bloc {
		width: 80%;
		margin: 20px 0;
	}
	#accompagnement .banniere__bloc > .bloc__top::before {
		top: 41%;
	}
	#accompagnement .bloc__top {
		height: 270px;
	}
	.banniere__bloc--right > .bloc__top::before {
		opacity: 0.5;
		top: 45%;
		left: 52%;
	}
	#accompagnement .bloc__bottom p{
		padding-top: 70px;
	}
	.inscription__form form .form__img{
		width: 80px;
		height: 80px;
	}
	.inscription__form form .form__img::after {
		width: 80px;
		height: 80px;
	}
	.inscription__form form{
		padding: 80px 50px 25px 25%;
	}
	.inscription__form form p{
		padding-left: 25%;
	}
	.inscription__form form .input-submit{
		width: 90%;
	}

	/* Compte */
	#contenu_page #tableau_bord{
		margin: 0;
	}
	#contenu_page h1 {
		font-size: 32px;
	}
	#page_compte > .c_25,
	#contenu_page section > .c_25{
		width: 15%;
	}
	#page_compte > .c_50,
	#contenu_page section > .c_50{
		width: 70%;
	}
	#tableau_bord h2, #tableau_bord_fiche h2{
		font-size: 22px;
	}

	/* Agences */
	.agence--coord{
		width: 80%;
		margin: 0 10%;
	}
	.agence--coord img{
		display: none;
	}
	.agence--photo {
		margin: 20px 20% 0;
		width: 60%;
	}
	.agence--photo > div{
		position: relative;
		width: 100%;
		margin: 25px 0;
	}
	#popup-info .popup-content {
		width: 75%;
	}
}

/* Téléphones (paysage) */
@media (max-width: 959px) {
	/* Global */
	table, table tr, table tr th, table tr td, table tbody {
		display:block;
		width:100% !important;	
		max-width:100% !important;
	}
	.colonne, .col {
		position:relative !important;
		display:block !important;
		float:none !important;
		clear:both !important;
		width:auto !important;
		min-width:inherit !important;
		max-width:inherit !important;
		margin:0 auto 15px auto;
	}

	h2, div.titre_h2{
		font-size: 24px;
	}

	/* Header mobile */
	#navigation{
		height: auto;
		flex-wrap: wrap;
		align-items: stretch;
		border-bottom: 1px solid #e8e8e8;
	}
	#logo{
		text-align: left;
	}
	#burger_menu{
		display: flex;
		justify-content: center;
		align-items: center;
		width: 20%;
	}
	#burger_menu i{
		transition: all 0.3s ease;
	}
	nav{
		display: none;
		width: 100% !important;
	}
	nav ul{
		flex-direction: column;
	}
	nav ul li{
		width: 100%;
		margin: 0;
		padding: 0;
		background: #f4f4f4;
		border-top: 1px solid #e8e8e8;
	}
	nav ul li a{
		display: block;
		padding: 20px 0;
	}
	#compte{
		display: none;
	}
	#compte_mobile{
		display: block;
		background-color: #f4f4f4;
		text-transform: uppercase;
		width: 20%;
		padding: 10px 0;
		margin-left: auto;
	}
	#compte_mobile a{
		text-align: center;
		font-size: 10px;
		display: block;
		color: #CF3124;
	}
	#compte_mobile img {
		margin: 0 auto 5px;
		display: block;
		width: 20px;
	}

	/* Accueil */
	#slider-offre .liste_offres--item {
		flex-direction: column;
	}
	#slider-offre .slick-dots {
		transform: translate(-50%, -50%) rotate(-90deg);
		left: 55%;
		top: 45%;
	}
	.offre-left.c_55, .offre-right.c_45 {
		width: 100%;
	}
	#slider-offre .offre-left {
		height: 20rem;
		clip-path: none;
	}
	#slider-offre .offre-right {
		padding: 2rem;
	}
	#slider-offre .offre-right::before {
		display: none;
	}

	#slider-offre .slick-arrow {
		position: absolute;
		left:auto;
		top: 19.25rem; /*hauteur de l'image dans le slider'*/
		height: 2rem;
		width: 2rem;
		border: none;
		color: transparent;
		background: url("/img/icone/chevron-left.svg") no-repeat center, #e21e26;
		background-size: auto;
		border-radius: 100%;
		z-index: 1000;
	}

	#slider-offre .slick-next {
		transform: scaleX(-1);
		transform-origin: center;
		right: 1rem;
		bottom:auto;
	}

	#slider-offre .slick-prev {
		transform:none;
		left:1rem;
		bottom:auto;
	}

	#banniere{
		height: auto;
		padding-top:16rem;
		margin-top: 4rem;
	}

	#banniere .blocs{
		flex-direction: column;
	}
	.banniere__bloc {
		width: 100%;
		margin: 50px 0;
	}
	.top-job > .job-desc{
		flex-wrap: wrap;
		padding-left: 40%;
	}
	.job-desc--left,
	.job-desc--right{
		width: 100%;
	}
	.top-job > .job-desc > .job-desc--left > p::before {
		left: -35px;
	}
	.section-title > img {
		width: 50px;
		height: 50px;
	}
	.form_offres > form > .form_top > .content > *{
		padding: 8px 5px;
	}
	.form_offres > form > .form_top > .content > input[type=submit]{
		font-size: 14px;
	}
	.form_offres > form > .form_bottom > .content > .filtre-tag > label{
		margin-bottom: 5px;
	}
	.liste_offres > .liste_offres--item {
		width: calc(100% / 2 - 20px);
	}
	.liste_offres > .liste_offres--item > .item--top {
		padding-left: 25%;
	}
	.liste_offres > .liste_offres--item > .item--top > p{
		font-size: 16px;
	}
	.liste_offres > .liste_offres--item > .item--img{
		width: 90px;
		height: 90px;
	}
	.liste_offres > .liste_offres--item > .item--bottom > p{
		font-size: 14px;
	}
	.stat_presentation > .stat_presentation--haut > p{
		font-size: 18px;
	}
	#statistiques .c_30 {
		width: 5%;
	}
	.stat_presentation {
		width: 90%;
	}
	.stat_presentation > .stat_presentation--bas{
		padding: 0;
	}
	.stat_presentation > .stat_presentation--bas > .presentation_bas--left,
	.stat_presentation > .stat_presentation--bas > .presentation_bas--right{
		transform: scale(0.8);
	}
	#partenaires > .container > p {
		width: 100%;
	}
	.liste-partenaires > img {
		max-width: 100px;
		max-height: 100px;
	}
	.liste_agence > .liste_agence--item{
		width: 25%;
	}
	

	/* Offre */
	.offre__content{
		flex-wrap: wrap;
		padding: 20px 10px 40px 5%;
	}
	.offre__content--left,
	.offre__content--right{
		width: 100%;
	}
	.offre__content--left{
		padding-left: 32%;
		margin-bottom: 20px;
	}
	.offre__content > .offre--img {
		width: 190px;
		height: 190px;
	}
	.form_offres > form > .form_bottom > .content > .filtre-agence > label,
	.form_offres > form > .form_bottom > .content > .filtre-tag > label,
	.form_offres > form > .form_bottom > .content > .filtre-type > label{
		font-size: 12px;
	}

	/* Trouver un candidat */
	#banniere-candidat .banniere__bloc {
		width: 90%;
	}
	#candidats > .container > p {
		width: 100%;
	}

	/* Notre accompagnement */
	.inscription__form{
		display: flex;
		flex-direction: column;
		margin-bottom: 50px;
	}
	.inscription__form::after{
		left: 0;
		width: 100%;
	}
	.inscription__form > .c_45{
		width: 100%;
	}
	.inscription__form form .form__img{
		left: 2%;
		top: 20px;
	}
	.inscription__form form .input-submit {
		position: relative;
		bottom: auto;
		left: auto;
		transform: none;
		width: auto;
		margin: 20px auto 10px;
	}

	/* Contact */
	.contact__content{
		flex-direction: column;
	}
	.contact__content > *{
		width: 80%;
		margin: 0 auto 30px;
	}

	/* Compte */
	#tableau_bord h2, #tableau_bord_fiche h2 {
		font-size: 20px;
	}
	#tableau_bord i, #tableau_bord_fiche h2 {
		font-size: 24px;
	}
	#tableau_bord, #tableau_bord_fiche{
		flex-wrap: wrap;
	}
	.tb_candidat > * {
		width: 45%;
	}
	#tableau_bord.tb_admin > *, #tableau_bord_fiche .tb_admin > * {
		width: 45%;
		margin: 0 10px !important;
	}
	#tableau_bord.tb_admin .tb_compte,
	#tableau_bord.tb_admin .tb_logout{
		margin: 10px !important;
	}
	#tableau_bord > div, #tableau_bord_fiche > div {
		flex-direction: row;
		align-items: stretch;
	}
	#tableau_bord > div > a,  #tableau_bord_fiche > div > a{
		padding: 10px 0;
	}
	#tableau_bord .tb_compte{
		margin: 0 10px 0 0 !important;
		width: 60%;
	}
	#tableau_bord .tb_logout{
		margin: 0 0 0 10px !important;
		width: 40%;
	}
	#tableau_bord .tb_logout h2{
		font-size: 14px;
	}
	.tb_recruteur > div{
		width: 100%;
		margin: 0 10px !important;
	}
	#tableau_bord.tb_recruteur .tb_compte,
	#tableau_bord.tb_recruteur .tb_logout{
		margin: 10px !important;
	}
	#page_compte > .c_25 {
		width: 10%;
	}
	#page_compte > .c_50 {
		width: 80%;
	}
	.form_pns input[type=submit],
	.form_pns button[type=submit],
	.form_pns input[type=button]{
		margin-top: 30px;
	}
	#contenu_page h1 {
		font-size: 26px;
	}
	.ligne_head{
		font-size: 12px;
		line-height: 1.2;
	}

	/* Relevés d'heures */
	.table-responsive{
		margin-top: 20px;
	}
	.table-responsive .ligne_head{
		display: none;
	}
	.table-responsive .ligne{
		display: flex;
		flex-direction: column;
	}
	.table-responsive .ligne div{
		text-align: left;
		margin-bottom: 15px;
		line-height: 16px;
		width: 100%;
	}
	.table-responsive .ligne div:before{
		position: relative;
		display: block;
		white-space: nowrap;
		text-transform: uppercase;
		font-size: 10px;
		color: #CF3124;
		content: attr(data-before);
	}
	.table-responsive .table-secondary{
		display: flex;
		flex-direction: row-reverse;
		justify-content: flex-end;
	}
	.table-responsive .table-secondary div{
		width: auto;
		margin-right: 10px;
	}

	.liste_agence--logo{
		display: none;
	}


}

/* Téléphones (paysage) */
@media (max-width: 769px) {
	/* Global */
	.colonne-s, .col-s {
		position:relative !important;
		display:block !important;
		float:none !important;
		clear:both !important;
		width:auto !important;
		min-width:inherit !important;
		max-width:inherit !important;
		margin:0 auto 15px auto;
	}

	/* Pages et form */
	.section-title{
		margin-bottom: 20px;
		padding: 0 5% 20px;
	}
	.form_pns .form_group {
		margin: 10px 0 30px;
	}
	#contenu_page section{
		padding: 30px 0 20px;
	}
	#inscription > .c_25{width: 10%;}
	#inscription > .c_50{width: 80%;}
	#inscription .no_mobile{display: none;}
	#inscription .col-s{margin-bottom: 0 !important;}

	.form_pns label, .form_pns .label_like{
		margin: 5px 0;
	}
	.form_pns input[type=submit],
	.form_pns button[type=submit],
	.form_pns input[type=button]{
		width: 100%;
	}

	/* Accueil */
	.banniere .no_mobile{
		display: none;
	}
	.banniere .title{
		width: 90%;
		margin: 0 5%;
	}

	#banniere .absolute {
		/*position: relative;*/
		position: absolute;
		left:50%;
		transform: translateX(-50%);
		top: -2rem;
		padding: 1.5rem;
		background: #b41818;
		color: #fff;
		border-radius: 5%;
		font-size: 16px;
		width: 18rem;
		height: auto;
		z-index: 10;
	}

	.top-job{
		padding: 0;
	}
	.top-job > p {
		padding: 7px 0;
		text-align: center;
	}
	.top-job > .job-desc{
		padding: 20px;
	}
	.top-job > .job-desc .no_mobile{
		display: none;
	}
	.top-job > .job-desc > .job-desc--img {
		position: relative;
		width: 150px;
		height: 150px;
		left: auto;
		top: auto;
		margin: auto;
	}
	.job-desc--left{
		padding-left: 35px;
	}
	.form_offres > form > .form_top > .content{
		justify-content: space-around;
		flex-wrap: wrap;
	}
	.form_offres > form > .form_top > .content > *{
		margin: 5px 0;
		width: 45%;
	}
	.form_offres > form > .form_bottom > .content > .filtre-agence > p,
	.form_offres > form > .form_bottom > .content > .filtre-tag > p {
		width: 100%;
	}
	.form_offres > form > .form_bottom > .content > .filtre-agence > label,
	.form_offres > form > .form_bottom > .content > .filtre-tag > label{
		margin: 0 5px 5px 0;
	}
	.form_offres > form > .form_bottom > .content > .filtre-type{
		justify-content: flex-start;
		flex-wrap: wrap;
	}
	.liste_offres > .liste_offres--item {
		width: 80%;
		margin: 0 10% 50px;
	}
	.liste_agence > .liste_agence--item{
		width: 33%;
	}
	.liste_agence > .liste_agence--item > a > .agence-nom{
		font-size: 16px;
	}
	.liste_agence > .liste_agence--item .bg_gris {
		width: 80px;
		height: 80px;
	}
	.liste_agence > .liste_agence--logo::before {
		width: 200px;
		height: 200px;
		left: -65%;
		top: -70%;
	}
	.liste_agence > .liste_agence--logo {
		width: 85px;
		height: 85px;
		top: -30%;
	}
	.footer-top{
		flex-wrap: wrap;
		padding: 10px 0 20px;
	}
	.footer-top > .form-footer,
	.footer-top > .rs-footer{
		width: 80%;
		margin: 0 10%;
	}
	.footer-top > .rs-footer{
		margin-top: 25px;
	}
	.footer-top hr{
		display: none;
	}

	/* Offre */
	.offre__content{
		padding: 20px;
	}
	.offre__content > .offre--img {
		position: relative;
		left: auto;
		top: auto;
		width: 100px;
		height: 100px;
		margin: auto;
	}
	.offre__content--left {
		padding-left: 45px;
	}
	.offre__content > .offre__content--right > button > a,
	.bouton, a.bouton{
		padding: 10px 15px;
	}

	/* Trouver un candidat */
	#banniere-candidat {
		height: auto;
	}
	#banniere-candidat .banniere__bloc{
		margin: 50px auto 20px;
	}
	.liste_cv > .liste_cv--item {
		width: 80%;
		margin: 0 10% 50px;
	}
	#banniere-candidat .banniere__bloc p:last-child a:before{
		top: 55%;
		left: 2%;
		height: 25px;
		width: 25px;
	}

	/* Notre accompagnement */
	#banniere-accompagnement {
		height: 400px;
	}
	.banniere .title h1 span{
		color: #ffffff;
	}
	.banniere_texte p:first-of-type {
		font-size: 40px;
		top: 15%;
		left: 5%;
	}
	.banniere_texte p:last-of-type{
		font-size: 30px;
		left: 35%;
		top: 35%;
	}
	.charte__liste {
		width: 70%;
	}
	#accompagnement .banniere__bloc > .bloc__top > p > a{
		font-size: 16px;
	}
	.banniere__bloc > .bloc__top > p > a::before {
		width: 20px;
		height: 20px;
		left: 2%;
	}
	#accompagnement .banniere__bloc > .bloc__top > p:nth-of-type(2) {
		font-size: 20px;
	}
	#accompagnement .banniere__bloc > .bloc__top > p {
		font-size: 14px;
	}
	.banniere__bloc > .bloc__top > .img-rond,
	.banniere__bloc > .bloc__top > .img-rond > img{
		width: 120px;
		height: 120px;
	}
	#accompagnement .banniere__bloc {
		margin: 20px 0;
	}
	.banniere__bloc > .bloc__top::before{
		left: 52%;
	}
	#accompagnement .bloc__bottom p {
		font-size: 14px;
		padding-top: 55px;
	}

	/* Compte */
	.tb_creer_cv{
		width: 100%;
	}
	#tableau_bord .tb_compte,
	#tableau_bord .tb_logout{
		width: 50%;
	}
	#tableau_bord a,  #tableau_bord_fiche a{
		padding: 10px;
	}
	#tableau_bord.tb_admin > * {
		width: 100%;
	}
	#tableau_bord.tb_entreprise{
		flex-direction: column;
	}
	.tb_entreprise > * {
		width: 95%;
	}
	#page_compte .groupe-bt{
		flex-direction: column-reverse;
		align-items: flex-start;
	}
	#page_compte .groupe-bt input{
		white-space: normal;
		line-height: 1.4;
	}
	#page_compte .groupe-bt form{
		margin-left: 0 !important;
		margin-bottom: 0 !important;
	}
	#page_compte .groupe-bt button{
		margin: 0;
	}
	#page_compte > .c_25, #contenu_page section > .c_25 {
		width: 5%;
	}
	#page_compte > .c_50, #contenu_page section > .c_50 {
		width: 90%;
	}
	#tableau_offres .ligne_head{
		display: none;
	}
	#tableau_offres .ligne{
		display: flex;
		flex-wrap: wrap;
	}
	#tableau_offres .ligne > *{
		width: 50%;
		text-align: left;
		margin: 2px 0;
	}
	.bt-retour,
	a.bt-retour{
		padding: 0;
	}
	.bt-secondary,
	a.bt-secondary{
		font-size: 12px;
		padding: 7px 12px;
		margin: 10px 0 20px !important;
	}

	/* Agences */
	.agence--coord{
		width: 90%;
		margin: 0 5%;
	}
	.agence--coord p{
		font-size: 14px;
	}
	.agence--photo {
		margin: 20px 5% 0;
		width: 90%;
	}
	.agence--photo p{
		padding: 15px 20px;
	}

	/* Relevés d'heures */
	.table_entry{
		padding: 15px 20px;
		width: 100%;
	}
	#popup-info .popup-content {
		width: 95%;
	}

	/* Actualités */
	.news-frame {
		flex-direction: column;
		margin-bottom: 1.5rem;
		width: 100%;
	}
	.news-frame__content {
		margin-left: initial;
	}
	.news-frame__image, .news-frame__content {
		width: 100%;
	}

}

/* Téléphones (portrait) */
@media (max-width: 479px) {
	/* Global */
	.colonne-xs, .col-xs {
		position:relative !important;
		display:block !important;
		float:none !important;
		clear:both !important;
		width:auto !important;
		min-width:inherit !important;
		max-width:inherit !important;
		margin:0 auto 15px auto;
	}

	h1, div.titre_h1 {
		font-size: 24px;
	}
	#contenu_page h1 {
		font-size: 22px;
		margin-bottom: 0;
		margin-top: 20px;
	}
	h2, div.titre_h2 {
		font-size: 20px;
	}

	/* Header */
	#logo {
		width: 55%;
	}
	#compte_mobile{
		width: 25%;
	}

	/* Accueil */
	#slider-offre .slick-dots {
		left: 50%;
		top: 40%;
	}
	.liste_agence > .liste_agence--item{
		width: 50%;
	}
	.banniere .title h1 {
		font-size: 30px;
	}
	.banniere .title h1 span {
		font-size: 22px;
	}
	.banniere__bloc > .bloc__top {
		padding: 65px 0 15px;
	}
	.banniere__bloc > .bloc__top > p {
		font-size: 14px;
	}
	.banniere__bloc > .bloc__bottom > p:first-of-type{
		font-size: 16px;
	}
	.banniere__bloc > .bloc__bottom > p{
		font-size: 14px;
	}
	.section-title > img {
		width: 40px;
		height: 40px;
	}
	.section-title:after{
		width: 90%;
	}
	.top-job > .job-desc > .job-desc--img {
		width: 110px;
		height: 110px;
	}
	.top-job > .job-desc > .job-desc--left > p{
		font-size: 14px;
	}
	.top-job > .job-desc > .job-desc--right > p{
		font-size: 12px;
	}
	.liste_offres > .liste_offres--item > .item--img{
		display: none;
	}
	.stat_presentation > .stat_presentation--haut > p {
		font-size: 16px;
	}
	.stat_presentation > .stat_presentation--bas > .presentation_bas--left,
	.stat_presentation > .stat_presentation--bas > .presentation_bas--right {
		transform: scale(0.65);
	}
	.stat_presentation > .stat_presentation--bas p{
		font-size: 28px;
	}
	.liste-partenaires{
		flex-wrap: wrap;
	}
	.liste_agence > .liste_agence--logo {
		display: none;
	}
	#agences::before{
		width: 200%;
	}
	.bloc_agence .liste_agence {
		width: 100%;
	}
	.footer-top > .form-footer, .footer-top > .rs-footer {
		width: 90%;
		margin: 0 5%;
	}
	.footer-top > .form-footer > form{
		display: flex;
		flex-direction: column;
	}
	.footer-top > .form-footer > form::before{
		top: 30px;
		left: 6%;
	}
	.footer-top > .form-footer > form > input[type=email]{
		padding-left: 40px;
		margin-bottom: 5px;
	}
	.footer-top > .form-footer > form > input[type=email],
	.footer-top > .form-footer > form > input[type=submit]{
		width: 100%;
	}
	.footer-top > .form-footer, .footer-top > .rs-footer {
		margin: 10px 5%;
	}
	.liste_offres > .liste_offres--item {
		width: 100%;
		margin: 0 0 30px;
	}
	.liste_offres > .liste_offres--item > .item--top {
		padding-left: 10px;
	}
	.liste_offres > .liste_offres--item > .item--bottom{
		padding: 10px 10px 10px 17%;
	}
	.liste_offres--item > .item--bottom > button > a{
		font-size: 14px;
	}
	.liste_agence > .liste_agence--item .bg_gris {
		width: 90px;
		height: 90px;
	}

	/* Offres */
	.offre__content {
		padding: 10px;
	}
	.offre__content > .offre__content--left > p{
		font-size: 14px;
	}
	#add_offer_to_fav{
		font-size: 14px;
		padding: 5px 10px;
	}

	/* Trouver un candidat */
	.banniere__bloc > .bloc__bottom {
		padding: 5px 10px;
	}
	#banniere-candidat .banniere__bloc p:last-child{
		width: 100%;
	}
	#banniere-candidat .banniere__bloc p:last-child a:before{
		display: none;
	}
	#candidats > .container > p{
		font-size: 14px;
	}
	.liste_cv > .liste_cv--item {
		width: 100%;
		margin: 0 0 50px;
	}
	.liste_cv > .liste_cv--item > .item--img {
		left: -5%;
		width: 80px;
		height: 80px;
	}
	.liste_cv > .liste_cv--item > .item--top{
		padding-left: 25%;
	}
	.liste_cv > .liste_cv--item > .item--bottom{
		padding: 30px 0 30px 20%;
	}
	.liste_cv > .liste_cv--item > .item--bottom > p{
		font-size: 14px;
		padding-top: 10px;
	}

	/* Notre accompagnement */
	#banniere-accompagnement {
		height: 350px;
	}
	.banniere_texte{
		margin-top: 65px;
		height: auto;
	}
	.banniere_texte p:first-of-type,
	.banniere_texte p:last-of-type{
		position: relative;
		top: auto;
		border: 0;
		width: 90%;
		margin: 0 5%;
		padding: 0;
	}
	.charte__arrow .arrow{
		width: 35px;
		height: 35px;
	}
	.charte__arrow .arrow img {
		height: 20px;
	}
	.charte__liste {
		width: 90%;
	}
	#accompagnement .banniere__bloc--left > .bloc__top > p > a::before,
	#accompagnement .banniere__bloc--right > .bloc__top > p > a::before{
		display: none;
	}
	#accompagnement .banniere__bloc > .bloc__top > p:nth-of-type(2) {
		font-size: 16px;
	}
	#accompagnement .banniere__bloc > .bloc__top > p {
		font-size: 12px;
		margin-top: 10px;
	}
	#accompagnement .bloc__top {
		height: 300px;
	}
	.inscription__form form {
		padding: 0 5px 10px;
	}
	.inscription__form form .form__img{
		display: none;
	}
	.inscription__form form p{
		position: relative;
		padding: 10px;
		font-size: 16px;
		margin-bottom: 10px;
	}
	.inscription__form form input,
	.inscription__form form a{
		margin: 10px 30px;
	}
	.inscription__form form .input-submit input{
		font-size: 14px;
		padding: 10px 25px;
	}

	/* Agences */
	.agence--photo > div{
		padding: 10px 15px;
		margin-bottom: 0;
	}
	.agence--photo > div p,
	.agence--photo > div li{
		font-size: 14px;
	}

	/* Contact */
	.contact__content > * {
		width: 100%;
	}
	.form_pns label,
	.form_pns .label_like{
		font-size: 14px;
	}
	a#lien_inscription {
		font-size: 18px;
	}
	.form_pns input[type=text],
	.form_pns input[type=email],
	.form_pns input[type=tel],
	.form_pns input[type=password],
	.form_pns input[type=file],
	.form_pns input[type=url],
	.form_pns input[type=number],
	.form_pns select,
	.chosen-container,
	.chosen-single,
	.chosen-choices,
	.form_pns textarea{
		font-size: 14px;
	}
	.form_pns input[type=submit],
	.form_pns button[type=submit],
	.form_pns input[type=button]{
		font-size: 14px;
		padding: 10px;
	}

	/* Compte */
	#contenu_page #tableau_bord{
		flex-direction: column;
	}
	.tb_candidat > *,
	.tb_entreprise > *,
	.tb_recruteur > * {
		width: 96%;
		margin: 10px 2% !important;
	}
	#tableau_offres .ligne > * {
		width: 100%;
		text-align: left;
		margin: 2px 0;
	}
	#tableau_offres .ligne > .c_05 {
		width: 33%;
	}/*
	#tableau_bord > div {
		flex-direction: column;
	}*/
	#tableau_bord > div:not(:last-of-type) {
		flex-direction: column;
	}
	#tableau_bord.tb_admin {
		flex-direction: column;
	}
	#tableau_bord.tb_admin > * {
		margin: 0 !important;
	}
	#tableau_bord.tb_admin > *:not(:last-of-type) {
		width: 95%;
	}
	#tableau_bord i {
		font-size: 22px;
	}
	#tableau_bord h2 {
		font-size: 18px;
		margin: 5px 0;
	}
	.tb_entreprise > * {
		width: 95%;
	}
	#tableau_bord.tb_recruteur > div{
		margin: 0 !important;
	}
	#tableau_bord.tb_recruteur > div:not(:last-of-type) {
		flex-direction: column;
		margin: 0 !important;
		padding: 0 10px;
	}
	#tableau_bord.tb_recruteur > div > a {
		margin: 10px 0 !important;
	}
	.form_pns .col-xs{
		margin-bottom: 0;
	}
	.form_pns .no_mobile{
		display: none;
	}
	#page_compte > .c_25, #contenu_page section > .c_25 {
		display: none;
	}
	#page_compte > .c_50, #contenu_page section > .c_50 {
		width: 100%;
	}
	#contenu_page section{
		font-size: 12px;
	}

	/* Relevés d'heures */
	.table-responsive{
		font-size: 14px;
	}
	.table_entry{
		padding: 15px 0;
	}
	.timingfield{
		width: 100% !important;
	}

	/* CV */
	.show_resume .bords_arrondis{
		border: none;
		padding: 0;
		box-shadow: none;
	}

	/* Actualités */
	.news-frame__sub {
		flex-direction: column;
		justify-content: center;
		margin-top: 0;
	}
	.news-frame__read {
		margin-left: initial;
	}

}