@charset "utf-8";
/* main.css // Atiweb // www.atiweb.fr */

/* HTML */
*, ::after, ::before {box-sizing: border-box;}
body {background-color:#FFF; color:#000; font-family:Arial, Helvetica, sans-serif;}
html, body, form, p, h1, h2, h3, h4, h5, h6 {margin:0; padding:0;}
img {vertical-align:middle;}

/* Liens */
a.tdeco_none, a.tdeco_none:hover {text-decoration:none;}
a img {vertical-align:middle; border:none;}
.pointer {cursor: pointer;}

/* HTML 5 */
article, aside, details, figcaption, figure, footer, header, hgroup, nav, section {display:block;}

/* Elements */
table, td, blockquote, code, pre, textarea, input, iframe, object, embed, video {max-width:100%;}
textarea, table, td, th, code, pre, samp, div, p {word-wrap:break-word;}
code, pre, samp {white-space:pre-wrap;}

/* Tableau */
.row {
	position:relative;
    display:table;
    table-layout:fixed;
    width:100%;
}
.row .col {
	position:relative;
    display:table-cell;
}

/* Couleurs */
.gris {color:#999999;}
.gris_fonce {color:#666666;}
.rouge {color:#CC0000;}
.bleu {color:#336699;}
.jaune {color:#FFCC00;}
.orange {color:#FF6600;}
.vert {color:#009933;}
.blanc {color:#FFFFFF;}
.noir {color:#000000;}

/* Mise en page */
.f_10 {font-size:10px;}
.f_11 {font-size:11px;}
.f_12 {font-size:12px;}
.f_13 {font-size:13px;}
.f_14 {font-size:14px;}
.f_15 {font-size:15px;}
.f_16 {font-size:16px;}
.f_17 {font-size:17px;}
.f_18 {font-size:18px;}
.f_19 {font-size:19px;}
.f_20 {font-size:20px;}
.f_21 {font-size:21px;}
.f_22 {font-size:22px;}
.f_23 {font-size:23px;}
.f_24 {font-size:24px;}
.f_26 {font-size:26px;}
.f_28 {font-size:28px;}
.f_30 {font-size:30px;}
.f_32 {font-size:32px;}
.minuscule {text-transform:lowercase;}
.majuscule {text-transform:uppercase;}

/* Effets, alignements, bords */
.shadow {text-shadow: 0px 0px 3px #FFA200;}
.center, .centre {text-align:center;}
.right, .droite {text-align:right;}
.left, .gauche {text-align:left;}
.justify, .justifier {text-align:justify;}
.center_v {vertical-align:middle;}
.top_v {vertical-align:top;}
.bottom_v {vertical-align:bottom;}
.bords {position:relative;padding:10px;border:1px solid #CCCCCC;}
.bords_bas {position:relative;padding-bottom:3px;margin-bottom:3px;border-bottom:1px solid #CCCCCC;}
.bords_arrondis {position:relative;padding:10px;border:1px solid #CCCCCC;background-color:#FFF;border-radius:20px;-moz-border-radius:20px;-webkit-border-radius:20px;-ms-border-radius:20px;-o-border-radius:20px;}
.bords_ombres {position:relative;padding:10px;border:1px solid #CCCCCC;background-color:#FFF; box-shadow:0px 0px 6px #aaa;-moz-box-shadow:0px 0px 6px #ccc;-webkit-box-shadow:0px 0px 6px #ccc;-ms-box-shadow:0px 0px 6px #ccc;-o-box-shadow:0px 0px 6px #ccc;}
.box_droite, .colonne_droite {position:relative; float:right;}
.box_gauche, .colonne_gauche {position:relative; float:left;}
.marge_0 {margin:0}
.pad_0 {padding:0}
.hidden, .c_hidden, .c-hidden, .form-hidden, .cadre-hidden {display:none;}
.inb {float:none !important; display:inline-block;margin-left: -0.25em;}
.transparent {
    -khtml-opacity:0; 
    -moz-opacity:0; 
    -ms-filter:"alpha(opacity=0)"; 
    filter:progid:DXImageTransform.Microsoft.Alpha(opacity=0); 
    filter:alpha(opacity=0); 
    opacity:0;	
}

/* Colonnes */
.colonne_gauche {position:relative;float:left;}
.colonne_droite {position:relative;float:right;}
.colonne_20 {position:relative;float:left;width:20px;}
.colonne_30 {position:relative;float:left;width:30px;}
.colonne_40 {position:relative;float:left;width:40px;}
.colonne_50 {position:relative;float:left;width:50px;}
.colonne_60 {position:relative;float:left;width:60px;}
.colonne_70 {position:relative;float:left;width:70px;}
.colonne_80 {position:relative;float:left;width:80px;}
.colonne_90 {position:relative;float:left;width:90px;}
.colonne_100 {position:relative;float:left;width:100px;}
.colonne_110 {position:relative;float:left;width:110px;}
.colonne_120 {position:relative;float:left;width:120px;}
.colonne_130 {position:relative;float:left;width:130px;}
.colonne_140 {position:relative;float:left;width:140px;}
.colonne_150 {position:relative;float:left;width:150px;}
.colonne_160 {position:relative;float:left;width:160px;}
.colonne_170 {position:relative;float:left;width:170px;}
.colonne_180 {position:relative;float:left;width:180px;}
.colonne_190 {position:relative;float:left;width:190px;}
.colonne_200 {position:relative;float:left;width:200px;}
.colonne_210 {position:relative;float:left;width:210px;}
.colonne_220 {position:relative;float:left;width:220px;}
.colonne_230 {position:relative;float:left;width:230px;}
.colonne_240 {position:relative;float:left;width:240px;}
.colonne_250 {position:relative;float:left;width:250px;}
.colonne_300 {position:relative;float:left;width:300px;}
.colonne_350 {position:relative;float:left;width:350px;}
.colonne_400 {position:relative;float:left;width:400px;}
.colonne_450 {position:relative;float:left;width:450px;}
.colonne_500 {position:relative;float:left;width:500px;}
.colonne_550 {position:relative;float:left;width:550px;}
.colonne_600 {position:relative;float:left;width:600px;}
.colonne_650 {position:relative;float:left;width:650px;}
.colonne_700 {position:relative;float:left;width:700px;}
.colonne_750 {position:relative;float:left;width:750px;}
.colonne_800 {position:relative;float:left;width:800px;}
.colonne_850 {position:relative;float:left;width:850px;}
.colonne_900 {position:relative;float:left;width:900px;}
.c_01 {position:relative;float:left;width:1%;}
.c_02 {position:relative;float:left;width:2%;}
.c_03 {position:relative;float:left;width:3%;}
.c_04 {position:relative;float:left;width:4%;}
.c_05 {position:relative;float:left;width:5%;}
.c_06 {position:relative;float:left;width:6%;}
.c_07 {position:relative;float:left;width:7%;}
.c_08 {position:relative;float:left;width:8%;}
.c_09 {position:relative;float:left;width:9%;}
.c_10 {position:relative;float:left;width:10%;}
.c_15 {position:relative;float:left;width:15%;}
.c_20 {position:relative;float:left;width:20%;}
.c_25 {position:relative;float:left;width:25%;}
.c_30 {position:relative;float:left;width:30%;}
.c_33 {position:relative;float:left;width:33.333333%;}
.c_35 {position:relative;float:left;width:35%;}
.c_40 {position:relative;float:left;width:40%;}
.c_45 {position:relative;float:left;width:45%;}
.c_50 {position:relative;float:left;width:50%;}
.c_55 {position:relative;float:left;width:55%;}
.c_60 {position:relative;float:left;width:60%;}
.c_65 {position:relative;float:left;width:65%;}
.c_70 {position:relative;float:left;width:70%;}
.c_75 {position:relative;float:left;width:75%;}
.c_80 {position:relative;float:left;width:80%;}
.c_85 {position:relative;float:left;width:85%;}
.c_90 {position:relative;float:left;width:90%;}
.c_95 {position:relative;float:left;width:95%;}
.c_100 {position:relative;float:left;width:100%;}
.fl_05 {flex-basis:5%;}
.fl_10 {flex-basis:10%;}
.fl_15 {flex-basis:15%;}
.fl_20 {flex-basis:20%;}
.fl_25 {flex-basis:25%;}
.fl_30 {flex-basis:30%;}
.fl_33 {flex-basis:33.333333%;}
.fl_35 {flex-basis:35%;}
.fl_40 {flex-basis:40%;}
.fl_45 {flex-basis:45%;}
.fl_50 {flex-basis:50%;}
.fl_55 {flex-basis:55%;}
.fl_60 {flex-basis:60%;}
.fl_65 {flex-basis:65%;}
.fl_70 {flex-basis:70%;}
.fl_75 {flex-basis:75%;}
.fl_80 {flex-basis:80%;}
.fl_85 {flex-basis:85%;}
.fl_90 {flex-basis:90%;}
.fl_95 {flex-basis:95%;}
.col_g, .col_d {
	position:relative;
	float:left;
	width:49%;
	margin-right:1%;	
}
.col_d {margin:0;}
.clear {clear:both;}
.clear_5, .clear_form {clear:both;height:5px;overflow:hidden;}
.colonne_400_index {position:relative;width:398px;float:left;padding:10px 25px 20px 25px;border:1px solid #CCCCCC;overflow:hidden;}
.colonne_250_bords {position:relative;width:228px;float:left;padding:10px;border:1px solid #CCCCCC;overflow:hidden;}
.colonne_300_bords {position:relative;width:278px;float:left;padding:10px;border:1px solid #CCCCCC;overflow:hidden;}
.colonne_350_bords {position:relative;width:328px;float:left;padding:10px;border:1px solid #CCCCCC;overflow:hidden;}
.colonne_450_bords {position:relative;width:428px;float:left;padding:10px;border:1px solid #CCCCCC;overflow:hidden;}
.colonne_550_bords {position:relative;width:528px;float:left;padding:10px;border:1px solid #CCCCCC;overflow:hidden;}
.col_m_20, .col_m_30, .col_m_50, .col_m_100 {
	display:inline-block;
	vertical-align:middle;
}
.col_m_20 {margin-right:20px;}
.col_m_30 {margin-right:30px;}
.col_m_50 {margin-right:50px;}
.col_m_100 {margin-right:100px;}

/* Cadres */
.inb {display:inline-block;}
.flex_row, .flex_row_st {
	display:flex;
	display:-webkit-box;
  	display:-webkit-flex;
  	display:-ms-flexbox;
	flex-flow:row wrap;
	-webkit-flex-flow:row wrap;
	-ms-flex-flow:row wrap;
	justify-content:space-between;	
	-webkit-justify-content:space-between;	
	-ms-justify-content:space-between;
}
.flex_row_st {
	justify-content: flex-start;
}
.flex_column {
	display:flex;
	display:-webkit-box;
  	display:-webkit-flex;
  	display:-ms-flexbox;
	flex-flow:column wrap;
	-webkit-flex-flow:column wrap;
	-ms-flex-flow:column wrap;
	justify-content:space-between;	
	-webkit-justify-content:space-between;	
	-ms-justify-content:space-between;	
}
.box_ligne, .box_ligne_2, .box_ligne_sm {
	position:relative;
	float:left;
	width:100%;
	min-height: 30px;
	border-bottom:1px solid #E4E7E9;
	line-height:26px;
	padding: 5px 0 0 0;
	background-color:#FFFFFF;
}
.box_ligne.titres {
	font-weight: bold;
	background: #E4E7E9;
	opacity: 0.8;
	color: #000;
}
.box_ligne.titres a {color: #000;}
.box_ligne_2 {background-color:#F3F3F3;}
.box_ligne_sm {line-height:20px;}
.box_ligne:hover, .box_ligne_2:hover, .box_ligne_sm:hover {background-color:#F6F6F6;}
.box_ligne input {margin-top:3px;}
.box_ligne div:after {
	display:block;
	margin:0.1px;
	content:'';
}	
.box_ligne .i-modif, .box_ligne .i-suppr {
	cursor: pointer;
}

/* Fomulaire */
form {margin:0; padding:0; border:none;}
input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill, textarea:-webkit-autofill, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus {
	border: none;
}
input[type=text], input[type=email], input[type=password], select, textarea, .input {
	position:relative;
	background:transparent;
	color:#0A4861;
	padding: 5px;
	border: none;
	border-bottom:1px solid #c1c8ca;
	border-right:1px solid #c1c8ca;
	font-size: 14px;
	vertical-align:middle;
	line-height: normal;
}
input[type=text]:focus, input[type=email]:focus, input[type=password]:focus, select:focus, textarea:focus, .input:focus {
	border: none;
	border-bottom:1px solid #336699;
	border-right:1px solid #336699;
}
input[type=text].readonly {
	background-color: #DDD;
}
label {
	font-weight:normal;
}
select[multiple] {overflow: auto;}

/* Boutons : liens + submit */
input[type=submit], input[type=button], a.bouton, a.button {
	position:relative;
	display:inline-block;
	border: none;
	background:#336699;
	color: #fff;
	padding: 5px 15px;
	border-radius: 5px;
	font-size: 14px;
	vertical-align: middle;
	text-decoration: none;
	cursor: pointer;
}
input[type=submit]:hover, input[type=button]:hover, a.bouton:hover, a.button:hover {
	opacity: 0.8;
}
.bt-large {
	display: block !important;
	width: 100% !important;
	text-align: center;
	padding: 10px !important;
}

/* Boutons : liens */
a.bouton, a.button {}
a.bouton:hover, a.button:hover {}

/* Pages */
.liens_pages {
	position:relative;
	clear:both;
	height:30px;
}
.lien_page, a.lien_page {
	position:relative;
	display:inline-block;
	border:1px solid #CCCCCC;
	padding:4px 8px;
	margin:0 2px 2px 0;
	border-radius:10px;
	text-decoration:none;
}
strong.lien_page, a.lien_page:hover, .lien_page_sel {
	background-color:#336699;
	color:#FFF;
	text-decoration:none;
}

/* Fil d'Ariane */
.fil_ariane {
	position:relative;
	padding:10px;
	border:1px solid #CCC;
	margin:10px 0px 10px 0px;
	color:#999;
	font-weight:bold;
	border-radius:10px;
}

/* Onglets */
.onglets {
	position: relative;
	padding-bottom: 2px;
	border-bottom:1px solid #EEE;
}
.onglet {
	position:relative;
	float:left;	
	margin-right:5px;	
}
.onglet a {
	position:relative;
	height:40px;
	line-height: 20px;
	display:block;
	padding:10px 20px;
	background-color:#FFF;
	border:1px solid #DDD;
	border-top:none;
	border-left:none;
	text-decoration:none;		
}
.onglet a:hover, .onglet a.onglet_actif {text-decoration:none; background-color:#336699; color:#FFF;}
.contenu_onglet, .onglet_cadre {
	position:relative;
	padding:20px 0px;
}

/* Icones */
.i_ajout, .i_modif, .i_suppr, .i_suppr2, .i_fv, .i_fb, .i_fr, .i_voir, .i_exit, .i_print, .i_fichier, .i_div, .i_excel {
	position:relative;
	display:block;
	background-position:0px 0px;
	background-repeat:no-repeat;
	padding-left:40px;
	min-height:32px;
	line-height:30px;
}
a.i_ajout, a.i_modif, a.i_suppr, a.i_suppr2, a.i_fv, a.i_fb, a.i_fr, a.i_voir, a.i_exit, a.i_print, a.i_fichier, a.i_excel, a.i_div {display:inline-block;}
.i_div { line-height:normal;padding-top:8px;}
.i_ajout {background-image:url(/img/32/ajout.png);}
.i_modif {background-image:url(/img/32/modif.png);}
.i_suppr {background-image:url(/img/32/suppr.png);}
.i_fv, .m_fv {background-image:url(/img/32/fleche-vert.png);}
.i_fb, .m_fb {background-image:url(/img/32/fleche-bleu.png);}
.i_fr, .m_fr {background-image:url(/img/32/fleche-rouge.png);}
.i_fichier {background-image:url(/img/32/fichier.png);}
.i_dossier {background-image:url(/img/32/dossier.png);}
.i_voir {background-image:url(/img/32/loupe.png);}
.i_exit {background-image:url(/img/32/exit.png);}
.i_print {background-image:url(/img/32/print.png);}
.i_reply {background-image:url(/img/32/reply.png);}
.i_forward {background-image:url(/img/32/forward.png);}
.i_gca {background-image:url(/img/32/gca.png);}
.i_excel {background-image:url(/img/32/excel.png);}
.m_actualiser, .onglet_actualiser {background-image:url(/img/32/actualiser.png);}
.m_actualites, .onglet_actualites, .m_informations, .onglet_informations, .m_infos, .onglet_infos {background-image:url(/img/32/actualites.png);}
.m_admin, .m_accueil, .onglet_admin {background-image:url(/img/32/admin.png);}
.m_aide, .onglet_aide {background-image:url(/img/32/aide.png);}
.m_ajout, .onglet_ajout {background-image:url(/img/32/ajout.png);}
.m_arborescence, .onglet_arborescence {background-image:url(/img/32/arborescence.png);}
.m_boutique, .onglet_boutique {background-image:url(/img/32/boutique.png);}
.m_calendrier, .onglet_calendrier {background-image:url(/img/32/calendrier.png);}
.m_camion, .onglet_camion {background-image:url(/img/32/camion.png);}
.m_cartes, .onglet_cartes {background-image:url(/img/32/cartes.png);}
.m_carton, .onglet_carton {background-image:url(/img/32/carton.png);}
.m_categories, .onglet_categories {background-image:url(/img/32/categories.png);}
.m_clients, .onglet_clients {background-image:url(/img/32/clients.png);}
.m_copie, .onglet_copie {background-image:url(/img/32/copie.png);}
.m_commentaires, .onglet_commentaires {background-image:url(/img/32/commentaires.png);}
.m_contenu, .onglet_contenu {background-image:url(/img/32/contenu.png);}
.m_couleurs, .onglet_couleurs {background-image:url(/img/32/couleurs.png);}
.m_crm, .onglet_crm {background-image:url(/img/32/crm.png);}
.m_cv, .onglet_cv {background-image:url(/img/32/cv.png);}
.m_document, .onglet_document {background-image:url(/img/32/document.png);}
.m_dossier, .onglet_dossier, .m_suivi, .onglet_suivi {background-image:url(/img/32/dossier.png);}
.m_etoile, .onglet_etoile {background-image:url(/img/32/etoile.png);}
.m_euro, .onglet_euro {background-image:url(/img/32/euro.png);}
.m_excel, .onglet_excel {background-image:url(/img/32/excel.png);}
.m_faq, .onglet_faq {background-image:url(/img/32/faq.png);}
.m_galerie_photos, .m_photos, .onglet_galerie_photos, .m_medias, .onglet_medias {background-image:url(/img/32/galerie-photos.png);}
.m_gca, .onglet_gca {background-image:url(/img/32/gca.png);}
.m_liste, .onglet_liste, .m_taches, .onglet_taches {background-image:url(/img/32/liste.png);}
.m_login, .onglet_login {background-image:url(/img/32/login.png);}
.m_loupe, .onglet_loupe {background-image:url(/img/32/loupe.png);}
.m_modif, .onglet_modif {background-image:url(/img/32/modif.png);}
.m_new_mail, .onglet_new_mail {background-image:url(/img/32/new-mail.png);}
.m_newsletter, .onglet_newsletter {background-image:url(/img/32/newsletter.png);}
.m_pdf, .onglet_pdf, .i_pdf {background-image:url(/img/32/pdf.png);}
.m_pj, .onglet_pj {background-image:url(/img/32/pj.png);}
.m_promo, .onglet_promo {background-image:url(/img/32/promo.png);}
.m_reglages, .onglet_reglages {background-image:url(/img/32/reglages.png);}
.m_save, .onglet_save {background-image:url(/img/32/save.png);}
.m_statistiques, .onglet_statistiques {background-image:url(/img/32/statistiques.png);}
.m_traductions, .onglet_traductions {background-image:url(/img/32/traductions.png);}
.m_tel, .onglet_tel {background-image:url(/img/32/tel.png);}
.m_valid, .onglet_valid {background-image:url(/img/32/valid.png);}
.m_telechargements, .onglet_telechargements {background-image:url(/img/32/telechargement.png);}
.m_print, .onglet_print {background-image:url(/img/32/print.png);}
.m_projets, .onglet_projets {background-image:url(/img/32/projets.png);}

/* Messages */
#txt_confirm, .txt_confirm, #msg_confirm, .msg_confirm {	
	position:relative;
	padding:10px;
	border:1px solid #009900;
	margin:20px 0px 20px 0px;
	color:#FFF;
	font-style:italic;
	font-size:13px; 
	font-weight:bold;
	border-radius:5px;
	background-color:#009936;
}
#txt_error, .txt_error, #txt_erreur, .txt_erreur, #msg_erreur, .msg_erreur {	
	position:relative;
	padding:10px;
	border:1px solid #000;
	margin:20px 0px 20px 0px;
	color:#FFF;
	font-style:italic;
	font-size:13px;
	font-weight:bold;
	border-radius:5px;
	background-color:#990000;
}
#txt_std, .txt_std, #msg_std, .msg_std {	
	position:relative;
	padding:10px;
	border:1px solid #CCC;
	margin:20px 0px 20px 0px;
	color:#333;
	font-style:italic;
	font-weight:bold;
	border-radius:5px;
	background-color:#EEE;
}

/* Totaux */
#totaux {
	position:relative;
	padding:15px;
	width:300px;
	border:1px solid #336699;
	border-radius:10px; 
}
/* Lignes des totaux */
.ligne_totaux {
	position:relative;
	background-color:#555;
	color:#FFF;
	padding: 0 10px;
	box-sizing: border-box;
}
.ligne_totaux:hover {
	background-color:#FFF;
	color:#000;
}

/* Loader */
.loader {
	margin: 50px auto;
	height: 28px;
	width: 28px;
	animation: rotate 0.8s infinite linear;
	border: 8px solid #336699;
	border-right-color: transparent;
	border-radius: 50%;
	display:inline-block;
}
@keyframes rotate {
	0%    { transform: rotate(0deg); }
	100%  { transform: rotate(360deg); }
}

/* Tooltip */ 
.tooltip {opacity: 1;}

/* Agenda */
.agenda_table {
	position: relative;
}
.agenda_table td {
	padding: 10px;
	vertical-align: top;
}
.agenda_table td a {
	position: relative;
	display: block;
}
.agenda_ligne, .agenda_ligne td {
	border: 1px solid #DDD;
}
.agenda_case_vide {
	position: relative;
	background-color: #EEE;
}
.agenda_num_jour {
	font-weight: bold;
	font-size: 18px;
	text-align: center;
	padding-bottom: 10px;
}
.agenda_evenement {
	margin-bottom: 5px;
}

/* Popup */
#popup_bg {
	position: fixed;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	background-color: #2391D2;
	z-index: 9000;
	opacity: 0;
}
#popup_c {
	position:fixed;
	text-align: center;
	width:70%;
	max-width: 70%;
	height:auto;
	max-height:70%;
	background: #fff;
	padding: 20px;
	z-index: 99999;
	box-shadow: 0 1px 4px 0 rgba(0,0,0,0.14);
	visibility: hidden;
	opacity: 0;
	transform: translate(0, -50%);
}
#popup_c .bt_close {
	position: absolute;
	top: 5px;
	right: 5px;
	cursor: pointer;
	font-weight: bold;
}